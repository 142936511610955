const locale = {
  LATER: "Nanti",
  PENDING_KYC: "KYC Tertunda",
  PENDING_KYC_DESC:
    "Sila lengkapkan proses KYC untuk meneruskan permohonan anda",
  AMOUNT: "Jumlah",
  CREATED: "Dicipta",
  DONT_SHOW_AGAIN: "Jangan tunjuk dialog lagi",
  KYC_PROCESS: "Proses KYC",
  CANCEL: "Batal",
  DIGITAL_SIGNATURE: "Tandatangan Digital",
  DIGITAL_SIGNATURE_SIGNATURE:
    "Sila lengkapkan proses Tandatangan Digital untuk meneruskan permohonan anda",
  SIGN_DOCUMENT: "Tandatangan Dokumen",
};

export default locale;
