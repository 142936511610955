import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Tabs,
  Badge,
  Button,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import ChatWindow from "./ChatWindow";
import StatusChip from "../../../../shared-components/statusChip/StatusChip";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationDetailData,
  getApplicationDetails,
  getApplicationStatus,
  getApplicationStatusData,
  getCurrentFinanceApplicationStatus,
  getSlug,
  setApplicationDetail,
} from "app/store/user/userSlice";
import {
  getDocumentTypeList,
  setDocumentTypeFilters,
} from "app/shared-components/Document/store/documentCardSlice";
import PersonalInfoAccordion from "./PersonalInfoAccordion";
import AddressInfoAccordion from "./AddressInfoAccordion";
import SiblingDetailsAccordion from "./SiblingDetailsAccordion";
import EmploymentDetailAccordion from "./EmploymentDetailAccordion";
import IncomeDetailAccordion from "./IncomeDetailAccordion";
import BankingDetailAccordion from "./BankingDetailAccordion";
import ProductDetailAccordion from "./ProductDetailAccordion";
import FinanceDetailAccordion from "./FinanceDetailAccordion";
import { formatAmount, getLocalStorageItem, hasPermission } from "src/app/services/utils";
import {
  generatePDF,
  getDocumentSignJPDF,
  getDocumentSignJPDFLoading,
  getGenerateFinanceApplicationPDFLoading,
  getIsActiveFinanceApplicationDialogOpen,
  getIsDocumentSignatureDialogOpen,
  getProcessSignatureLoading,
  resetAll,
  setActiveFinanceDetail,
  setIsActiveFinanceApplicationDialogOpen,
  setIsDocumentSignatureDialogOpen,
  setIsFinalSubmitSignatureDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import Tab from "@mui/material/Tab";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import ReferralDetailAccordion from "./ReferralDetailAccordion";
import NextOfKinDetailAccordion from "./NextOfKinDetailAccordion";
import ApplicationStatusStepper from "./ApplicationStatusStepper";
import FinalApplicationSubmit from "app/shared-components/FinalApplicationSubmit/FinalApplicationSubmit";
import { isEmpty } from "lodash";
import FuseLoading from "@fuse/core/FuseLoading";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import CustomTooltip from "app/shared-components/Tooltip/CustomTooltip";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import ChatIcon from "@mui/icons-material/Chat";
import history from "@history";
import {
  getIsNotificationRedirection,
  setIsNotificationRedirection,
} from "src/app/main/notification/store/notificationSlice";
import DocumentPage from "app/shared-components/Document/DocumentPage";
import {
  getDocumentTypeListData,
  getIsRequiredDocsAlertIsOpen,
} from "app/shared-components/Document/store/documentCardSlice";
import {
  documentSelectType,
  financeApplicationStatusEnum,
  KYCResultStatusEnum,
  loginUserRoles,
} from "src/app/common/constant";
import MissingDocumentsAlert from "app/shared-components/Alert/MissingDocumentAlert";
import { showFinanceApplicationDownloadPdf } from "src/app/services/utils";
import DigitalSignatureDetailAccordion from "./DigitalSignatureDetailAccordion";
import themesConfig from "app/configs/themesConfig";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const CustomBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-anchorOriginTopRight": {
    right: "-6px",
    top: "-1px",
  },
}));

export default function MyApplicationDetail() {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const userSlug = useSelector(getSlug);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [remarksList, setRemarksList] = useState({
    UserAdditionalInformation: [],
    UserAddress: [],
    UserRelativeDetail: [],
    UserReferralDetail: [],
    UserNextofkin: [],
    UserEmploymentDetail: [],
    UserIncomeDetail: [],
    UserBankingDetail: [],
    UserExistingFinance: [],
    UserDocuments: [],
  });
  const applicationDetailsData = useSelector(getApplicationDetailData);
  const currentTab = Number(params.get("tab")) || 0;
  const [tabValue, setTabValue] = React.useState(currentTab);
  const [kycDialogOpen, setIsKycDialogOpen] = React.useState(true);
  const [signatureDialogOpen, setIsSignatureDialogOpen] = React.useState(true);
  const applicationStatus = useSelector(getApplicationStatusData);
  const areAllRequiredDocumentsPresent = useSelector(
    getIsRequiredDocsAlertIsOpen
  );
  const isDocumentSignatureDialogOpen = useSelector(
    getIsDocumentSignatureDialogOpen
  );
  const [showRequireDocsAlert, setShowRequireDocsAlert] = useState(false);
  const documentTypes = useSelector(getDocumentTypeListData);
  const isPDFGenerateLoading = useSelector(
    getGenerateFinanceApplicationPDFLoading
  );
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const redirectFromNotification = useSelector(getIsNotificationRedirection);
  const isActiveFinanceApplicationDialogOpen = useSelector(
    getIsActiveFinanceApplicationDialogOpen
  );
  const isPermissionToCreateDigitalSignature = hasPermission(
    FeaturePermissions.DigitalSignature.Create
  );

  const hasPermissionToViewApplicationStatus = hasPermission(
    FeaturePermissions.FinanceApplicationProgress.List
  );

  const hasCommunicationPermission = hasPermission(
    FeaturePermissions.FinanceApplicationCommunication.List
  );

  if (!hasPermission(FeaturePermissions.FinanceApplication.View)) {
    return <ErrorMessage errorMessage="ACCESS_DENIED_VIEW_APPLICATION" />;
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const initialTabValue = query.get("tab")
      ? parseInt(query.get("tab") as string, 3)
      : 0;
    setTabValue(initialTabValue);
  }, [location.search]);

  useEffect(() => {
    if (redirectFromNotification) {
      dispatch(getApplicationDetails(applicationId));
      dispatch(setIsNotificationRedirection(false));
    }
  }, [redirectFromNotification]);

  useEffect(() => {
    dispatch(
      setDocumentTypeFilters({
        type: documentSelectType.finance,
      })
    );
    dispatch(getApplicationDetails(applicationId));
    dispatch(getDocumentTypeList());
  }, [dispatch]);

  function shouldOpenSignatureDialog(
    applicationDetailsData,
    signatureDialogOpen,
    isDocumentSignatureDialogOpen,
    lastShownDate,
    today,
    userSlug,
    isPermissionToCreateDigitalSignature
  ): boolean {
    return (
      signatureDialogOpen &&
      !isEmpty(applicationDetailsData) &&
      !isDocumentSignatureDialogOpen &&
      lastShownDate !== today &&
      userSlug === loginUserRoles.Customer &&
      applicationDetailsData.financeApplicationStatus.toLowerCase() ===
      financeApplicationStatusEnum.DocumentSigning.toLowerCase() &&
      !applicationDetailsData?.digitalSignatureDetails?.isCustomerSinged &&
      isPermissionToCreateDigitalSignature
    );
  }

  function shouldOpenKYCDialog(
    applicationDetailsData,
    kycDialogOpen,
    isActiveFinanceApplicationDialogOpen,
    lastShownDate,
    today,
    userSlug
  ): boolean {
    return (
      kycDialogOpen &&
      !isEmpty(applicationDetailsData) &&
      !isActiveFinanceApplicationDialogOpen &&
      lastShownDate !== today &&
      userSlug === loginUserRoles.Customer &&
      applicationDetailsData.financeApplicationStatus.toLowerCase() ===
      financeApplicationStatusEnum.PendingKYC.toLowerCase() &&
      applicationDetailsData?.ekycStatus.toLowerCase() !==
      KYCResultStatusEnum.success.toLowerCase()
    );
  }

  useEffect(() => {
    const lastShownDate = getLocalStorageItem("pendingKYCDialogDate");
    const today = new Date().toLocaleDateString();
    const isKYCDialogOpen = shouldOpenKYCDialog(
      applicationDetailsData,
      kycDialogOpen,
      isActiveFinanceApplicationDialogOpen,
      lastShownDate,
      today,
      userSlug
    );

    if (isKYCDialogOpen) {
      dispatch(setActiveFinanceDetail(applicationDetailsData));
      dispatch(setIsActiveFinanceApplicationDialogOpen(true));
      setIsKycDialogOpen(false);
    }

    const isSignatureDialogOpen = shouldOpenSignatureDialog(
      applicationDetailsData,
      signatureDialogOpen,
      isDocumentSignatureDialogOpen,
      lastShownDate,
      today,
      userSlug,
      isPermissionToCreateDigitalSignature
    );

    if (isSignatureDialogOpen) {
      dispatch(setActiveFinanceDetail(applicationDetailsData));
      dispatch(setIsDocumentSignatureDialogOpen(true));
      setIsSignatureDialogOpen(true);
    }
  }, [applicationDetailsData, kycDialogOpen, signatureDialogOpen]);

  useEffect(() => {
    if (hasPermissionToViewApplicationStatus) {
      dispatch(getApplicationStatus(applicationId));
    }
  }, [dispatch, hasPermissionToViewApplicationStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
      dispatch(setApplicationDetail({}));
    };
  }, []);

  useEffect(() => {
    if (applicationDetailsData) {
      const sectionNames = [
        "UserAdditionalInformation",
        "UserAddress",
        "UserRelativeDetail",
        "UserReferralDetail",
        "UserNextofkin",
        "UserEmploymentDetail",
        "UserIncomeDetail",
        "UserBankingDetail",
        "UserExistingFinance",
        "UserDocuments",
      ];
      const remarks: any = {};
      sectionNames.forEach((sectionName) => {
        const filteredRemark =
          applicationDetailsData?.financeApplicationSectionRemark?.filter(
            (message) =>
              message.remarkType === "External" &&
              message.sectionName === sectionName &&
              message.hasActionTaken !== true
          );
        remarks[sectionName] = filteredRemark;
      });
      setRemarksList(remarks);
    }
  }, [applicationDetailsData]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    history.push(`?tab=${newValue}`);
  };

  const handleOpen = () => {
    //TODO: Commenting this out for now, blocking for submit application from detail page
    // if(!areAllRequiredDocumentsPresent){
    //   setShowRequireDocsAlert(true);
    // } else {
    dispatch(setIsFinalSubmitSignatureDialogOpen(true));
    // }
  };

  const generateFinancePDF = () => {
    dispatch(generatePDF(applicationId));
  };

  const isAllFieldsCompleted =
    applicationDetailsData?.isUserAdditionalInformationCompleted &&
    applicationDetailsData?.isUserAddressCompleted &&
    // applicationDetailsData?.isUserRelativeDetailCompleted &&
    applicationDetailsData?.isUserReferralDetailCompleted &&
    applicationDetailsData?.isUserEmploymentDetailCompleted &&
    applicationDetailsData?.isUserIncomeDetailCompleted &&
    applicationDetailsData?.isUserBankingDetailCompleted;

  return (
    <Box
      sx={{
        backgroundColor: themesConfig.default.palette.background.default,
      }}
      className="h-full"
    >
      <Container
        maxWidth="lg"
        className="pl-10 md:pl-76 pr-10 md:pr-76 mt-10 mb-64"
      >
        <CommonBreadcrumbs
          pages={[
            {
              to: "/user/my-application",
              name: t("MY_APPLICATION"),
            },
          ]}
          currentPageName={t("DETAILS")}
        />
        {isEmpty(applicationDetailsData) ? (
          <FuseLoading />
        ) : (
          <Paper className="mb-20 p-20">
            <Grid container spacing={2} className="mb-20">
              <Grid item xs={12} sm={6} md={8}>
                <div className="flex flex-col md:flex-row items-start md:items-center grow gap-10">
                  <div className="flex flex-col w-full md:w-auto mt-10 md:mt-0 sm:pt-10 sm:pb-10">
                  <StyledTypography variant="h5" fontWeight="bold">
                      {`${applicationDetailsData.applicationNumber}`}
                    </StyledTypography>
                  <Typography
                    className="leading-none mt-2"
                    variant="body1"
                    color="textSecondary"
                  >
                    {applicationDetailsData?.userDetail?.fullName}
                  </Typography>
                </div>

                  <div className="flex gap-10 grow">
                    {applicationDetailsData?.financeApplicationStatus?.toLowerCase() ===
                    financeApplicationStatusEnum.Draft.toLowerCase() ? (
                      <CustomTooltip
                        title={
                          currentFinanceStatus === "rejected"
                            ? t("REJECTED_FINANCE_TOOLTIP")
                            : !isAllFieldsCompleted
                            ? t("FINAL_SUBMIT_TOOLTIP")
                            : ""
                        }
                      >
                        <span>
                          <ColoredSubmitButton
                            text={t("SUBMIT")}
                            className="ml-0 md:ml-10 px-20 py-5"
                            onClick={handleOpen}
                            disabled={
                              !isAllFieldsCompleted &&
                              currentFinanceStatus !== "rejected" // put condition here
                            }
                          />
                        </span>
                      </CustomTooltip>
                    ) : showFinanceApplicationDownloadPdf(
                        applicationDetailsData
                      ) ? (
                      <Can
                        permission={
                          FeaturePermissions?.FinanceApplication
                            ?.DownloadApplicationPdf
                        }
                      >
                        <CustomTooltip
                          title={
                            currentFinanceStatus === "rejected"
                              ? t("REJECTED_FINANCE_TOOLTIP")
                              : ""
                          }
                        >
                          <span>
                            <ColoredSubmitButton
                              text={t("DOWNLOAD_PDF")}
                              className="px-20 py-5 w-full md:w-144"
                              onClick={generateFinancePDF}
                              isLoading={isPDFGenerateLoading}
                              disabled={currentFinanceStatus === "rejected"}
                            />
                          </span>
                        </CustomTooltip>
                      </Can>
                    ) : null}
                    {userSlug === loginUserRoles.Customer &&
                      applicationDetailsData?.financeApplicationStatus?.toLowerCase() ===
                        financeApplicationStatusEnum.PendingKYC.toLowerCase() &&
                      applicationDetailsData?.ekycStatus &&
                      applicationDetailsData?.ekycStatus?.toLowerCase() !==
                        KYCResultStatusEnum.success.toLowerCase() && (
                        <Button
                          variant="contained"
                          color="success"
                          className="px-20 py-5 capitalize text-white"
                          onClick={() => {
                            history.push(
                              `/user/my-application-kyc/${encodeURIComponent(
                                applicationDetailsData?.id
                              )}`
                            );
                          }}
                        >
                          {t("KYC_PROCESS")}
                        </Button>
                      )}

                    {/* If Document sign is not True, add the DOCUMENT_SIGNED menu item */}
                    {userSlug === loginUserRoles.Customer &&
                      applicationDetailsData?.financeApplicationStatus?.toLowerCase() ===
                        financeApplicationStatusEnum.DocumentSigning.toLowerCase() &&
                      !applicationDetailsData?.digitalSignatureDetails
                        ?.isCustomerSinged && (
                        <Can
                          permission={FeaturePermissions.DigitalSignature.Create}
                        >
                          <Button
                            variant="contained"
                            color="success"
                            className="px-20 py-5 capitalize text-white"
                            onClick={() => {
                              dispatch(
                                setActiveFinanceDetail(applicationDetailsData)
                              );
                              dispatch(setIsDocumentSignatureDialogOpen(true));
                            }}
                          >
                            {t("DOCUMENT_SIGNED")}
                          </Button>
                        </Can>
                      )}
                  </div>
                </div>

                <FinalApplicationSubmit
                  phoneNumber={
                    applicationDetailsData?.userAdditionalInformation?.phoneNumber
                  }
                  applicationId={applicationId}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                md={2}
                className="flex items-center justify-end"
              >
                {applicationDetailsData?.financeApplicationStatus ? (
                  <StatusChip
                    label={applicationDetailsData?.financeApplicationStatus}
                    status={applicationDetailsData?.financeApplicationStatus}
                    className="leading-none"
                  />
                ) : (
                  <span>--</span>
                )}
              </Grid>
              <Grid
                item
                xs={8}
                sm={4}
                md={2}
                className="flex items-center justify-end"
              >
                <StyledTypography
                  variant="h6"
                  fontWeight={"bold"}
                  className="text-end"
                >
                  {formatAmount(applicationDetailsData?.financeAmount)}
                </StyledTypography>
              </Grid>
            </Grid>
            {hasPermissionToViewApplicationStatus && (
              <ApplicationStatusStepper applicationStatus={applicationStatus} />
            )}

            {/* error message alert for remaining required documents */}
            <MissingDocumentsAlert
              documentTypes={documentTypes}
              documentList={applicationDetailsData?.userDocuments}
              showRequireDocsAlert={showRequireDocsAlert}
              setShowRequireDocsAlert={setShowRequireDocsAlert}
              t={t}
            />

            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="basic tabs example"
                >
                  <Tab
                    icon={<ListAltIcon />}
                    iconPosition="start"
                    label={t("DETAILS")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    icon={<DescriptionIcon />}
                    iconPosition="start"
                    label={
                      <CustomBadge
                        badgeContent={
                          applicationDetailsData?.userDocuments?.length
                        }
                        color="error"
                      >
                        {t("DOCUMENTS")}
                      </CustomBadge>
                    }
                    {...a11yProps(1)}
                  />
                  {hasCommunicationPermission && (
                    <Tab
                      icon={<ChatIcon />}
                      iconPosition="start"
                      label={t("COMMUNICATION")}
                      {...a11yProps(2)}
                    />
                  )}
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <ProductDetailAccordion productDetail={applicationDetailsData} />
                <Can
                  permission={FeaturePermissions.UserAdditionalInformation.View}
                >
                  <PersonalInfoAccordion
                    personalDetails={
                      applicationDetailsData.userAdditionalInformation
                    }
                    remarksList={remarksList.UserAdditionalInformation}
                    financeUserId={applicationDetailsData?.userId}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserAddress.View}>
                  <AddressInfoAccordion
                    addressDetail={applicationDetailsData.userAddress}
                    remarksList={remarksList.UserAddress}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserRelativeDetail.View}>
                  <SiblingDetailsAccordion
                    relativeDetail={applicationDetailsData.userRelativeDetail}
                    remarksList={remarksList.UserRelativeDetail}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserReferralDetail.View}>
                  <ReferralDetailAccordion
                    referralDetail={applicationDetailsData.userReferralDetail}
                    remarksList={remarksList.UserReferralDetail}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserNextofkin.View}>
                  <NextOfKinDetailAccordion
                    nextOfKinDetail={applicationDetailsData.userNextofkin}
                    remarksList={remarksList.UserNextofkin}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserEmploymentDetail.View}>
                  <EmploymentDetailAccordion
                    employmentDetail={applicationDetailsData.userEmploymentDetail}
                    remarksList={remarksList.UserEmploymentDetail}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserIncomeDetail.View}>
                  <IncomeDetailAccordion
                    incomeDetail={applicationDetailsData.userIncomeDetail}
                    remarksList={remarksList.UserIncomeDetail}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserBankingDetail.View}>
                  <BankingDetailAccordion
                    bankingDetail={applicationDetailsData.userBankingDetail}
                    remarksList={remarksList.UserBankingDetail}
                  />
                </Can>
                <Can permission={FeaturePermissions.UserExistingFinance.View}>
                  <FinanceDetailAccordion
                    financeDetail={applicationDetailsData.userExistingFinance}
                    remarksList={remarksList.UserExistingFinance}
                  />
                </Can>
                {applicationDetailsData?.digitalSignatureDetails
                  ?.isCustomerSinged && (
                  <DigitalSignatureDetailAccordion
                    signatureDetail={
                      applicationDetailsData.digitalSignatureDetails
                    }
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <Can permission={FeaturePermissions.UserDocuments.View}>
                  <DocumentPage
                    {...{
                      financeApplicationId: applicationId,
                      remarksList: remarksList?.UserDocuments,
                    }}
                  />
                </Can>
              </CustomTabPanel>
              <Can
                permission={
                  FeaturePermissions.FinanceApplicationCommunication.List
                }
              >
                <CustomTabPanel value={tabValue} index={2}>
                  <ChatWindow />
                </CustomTabPanel>
              </Can>
            </Box>
          </Paper>
        )}
      </Container>
    </Box>
  );
}
