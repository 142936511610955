import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberListForFinance,
  getMemberListLoading,
  setMemberSearchKeyword,
  setMemberListLoading,
  getMemberUserId,
  setMemberListPagination,
  setMemberUserId,
} from "app/store/user/userSlice";
import SearchableAutocomplete from "../Form/SearchableAutocomplete";

function MemberAutoComplete({
  label,
  onChange,
  value,
  disabled,
  error,
  apiCallPermission = false,
  options = {
    showAddOption: false,
    onAddClick: () => { },
  },
  dataTestId = ""
}) {
  const dispatch: any = useDispatch();
  const loading = useSelector(getMemberListLoading);
  const defaultMemberSelect = useSelector(getMemberUserId);
  const [selectedMember, setSelectedMember] = useState(null);
  const [reLoadData, setIsReloadData] = useState(false);

  useEffect(() => {
    setSelectedMember(value);
  }, [value]);

  useEffect(() => {
    const fetchAndSetDefaultMember = async () => {
      if (!!defaultMemberSelect && !selectedMember) {
        const fetchedOptions = await fetchUserOptions("", 0);
        const foundMember = fetchedOptions.find(
          (member) => member.id === defaultMemberSelect
        );
        if (foundMember) {
          setIsReloadData(true);
          handleChange({ id: foundMember.id, label: foundMember.label });
        }
      }
    };

    fetchAndSetDefaultMember();
  }, [defaultMemberSelect, selectedMember]);

  const handleChange = (selectedValue) => {

    if (selectedValue?.id === "add") {
      options?.onAddClick?.();
      setSelectedMember(null);
      dispatch(setMemberListPagination({ pageIndex: 0 }));
    } else {
      onChange(selectedValue);
      setSelectedMember(selectedValue ? selectedValue.id : null);
      dispatch(setMemberListLoading(false));
    }
  };

  // Conditionally include the "Add Member" option based on the options.showAddMemberOption prop
  const extendedOptions = (newOptions) => {
    return [
      ...(options.showAddOption
        ? [
          {
            label: "ADD", // Label for the add option
            id: "add",
          },
        ]
        : []), // Only add this entry if showAddOption is true
      ...(newOptions?.map((member) => ({
        label: member?.userAdditionalInformation?.nationalId
          ? `${member.fullName} (${member?.userAdditionalInformation?.nationalId})`
          : member.fullName,
        id: member.id,
      })) || []),
    ]
  };
  const removeLastBrackets = (str) => {
    return str.replace(/\s*\(\d+\)$/, '');
  }

  const fetchUserOptions = async (searchTerm, pageIndex) => {
    if (apiCallPermission) {
      dispatch(setMemberSearchKeyword(removeLastBrackets(searchTerm)));
      dispatch(setMemberListPagination({ pageIndex: pageIndex }));

      const newOptions = await dispatch(getMemberListForFinance());
      return extendedOptions(newOptions);
    } else {
      return [];
    }
  };

  return (
    <SearchableAutocomplete
      label={label}
      value={selectedMember || null}
      onChange={(selectedOption) => handleChange(selectedOption)}
      fetchOptions={fetchUserOptions}
      isParentLoading={loading}
      error={error}
      reLoadData={reLoadData}
      required
      disabled={disabled}
      dataTestId={dataTestId}
    />
  );
}

export default MemberAutoComplete;
