import * as React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import themesConfig from "app/configs/themesConfig";
import {
  StyledTypography,
  StyledTypographyGrey,
} from "app/shared-components/Styles/CustomStyle";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getUserDocumentForFinanceForm,
  getUserDocumentListInFinanceForm,
  setCurrentStep,
  setIsDocumentDialogOpen,
  getUserFinanceDetailsDataInFinanceForm,
  getUserAdditionalInfoDataInFinanceForm,
  setIsFinalSubmitSignatureDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import {
  getApplicationDetails,
  getDocumentTypeList,
  getDocumentTypes,
} from "app/store/user/userSlice";
import {
  acceptFileTypeImageAndPDF,
  documentSelectType,
  tenantId,
} from "src/app/common/constant";
import { showMessage } from "app/store/fuse/messageSlice";
import api from "src/app/services/apiService";
import CircularProgress from "@mui/material/CircularProgress";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import FinalApplicationSubmit from "app/shared-components/FinalApplicationSubmit/FinalApplicationSubmit";
import MissingDocumentsAlert from "app/shared-components/Alert/MissingDocumentAlert";
import { isEmpty } from "lodash";

const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  textAlign: "center",
  width: "10% !important",
  fontSize: "13px",
  fontWeight: "400",
  border: `1px solid ${theme.palette.common.white}`,
  "@media (max-width: 650px)": {
    whiteSpace: "nowrap",
  },
}));

const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: themesConfig.legacy.palette.primary.main,
  border: `1px solid ${theme.palette.common.white}`,
  textAlign: "center",
  fontWeight: "500",
  padding: "10px",
  whiteSpace: "nowrap",
}));

const StyledButtonForOutlined = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  color: theme.palette.secondary.main,
  width: "100%",
  textTransform: "capitalize",
}));

const StyledTextField = styled(CustomTextField)(({ theme }) => ({
  backgroundColor: "transparent",
  "&  fieldset": {
    color: theme.palette.text,
  },
}));

const UploadDocument = ({
  financeApplicationId = null,
  isEdit = false,
  isRequired = false,
  setIsDirty = (value) => {},
}) => {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const documentType = useSelector(getDocumentTypes);
  const documentList = useSelector(getUserDocumentListInFinanceForm);
  const [search] = useSearchParams();
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;
  // State to manage file selection, password input, and upload status for each document
  const [selectedFiles, setSelectedFiles] = React.useState({});
  const [passwords, setPasswords] = React.useState({});
  const [uploads, setUploads] = React.useState({});
  const [filePaths, setFilePaths] = React.useState({});
  const [missingDocuments, setMissingDocuments] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(
    Array(documentType?.length).fill(false)
  );
  const [fileNames, setFileNames] = React.useState({});
  const [fileLoading, setFileLoading] = React.useState(
    Array(documentType?.length).fill(false)
  );
  const [isDirtyField, setIsDirtyField] = React.useState(false);
  const applicationData = useSelector(getUserFinanceDetailsDataInFinanceForm);
  const userData = useSelector(getUserAdditionalInfoDataInFinanceForm);
  const [otherDocumentName, setOtherDocumentName] = React.useState("");
  const [showRequireDocsAlert, setShowRequireDocsAlert] = React.useState(false);
  const [isForEditDocumentNameChange, setIsForEditDocumentNameChange] =
    React.useState(false);
  const [isOtherDocumentPath, setIsOtherDocumentPath] = React.useState(false);
  const [isOtherDocumentAdded, setIsOtherDocumentAdded] = React.useState<any>(
    {}
  );

  React.useEffect(() => {
    setIsDirty(isDirtyField);
  }, [isDirtyField, setIsDirty]);

  React.useEffect(() => {
    dispatch(
      getDocumentTypeList({ documentSelectType: documentSelectType.finance })
    );
  }, [dispatch]);

  React.useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getUserDocumentForFinanceForm(idToUse));
    }
  }, [dispatch, financeId, financeApplicationId]);

  React.useEffect(() => {
    documentList?.forEach(async (item) => {
      const { documentTypeId, documentFilePath } = item;

      // Check if documentPath is available
      if (documentFilePath) {
        // Find the index of the documentType based on its id
        const index = documentType?.findIndex(
          (doc) => doc.id === documentTypeId
        );

        // If the documentType is found
        if (index !== -1) {
          // Set the file path for the documentType
          setFilePaths((prev) => ({
            ...prev,
            [index]: documentFilePath,
          }));

          // Update the upload status for the documentType
          setUploads((prev) => ({
            ...prev,
            [index]: true,
          }));
        }
        const otherDocumentRecord = documentList?.find(
          (doc) => doc?.documentTypeSlug?.toLowerCase() === "other"
        );
        if (otherDocumentRecord?.documentTypeSlug?.toLowerCase() === "other") {
          setOtherDocumentName(otherDocumentRecord.documentName);
          otherDocumentRecord.documentFilePath && setIsOtherDocumentPath(true);
        }
      }
    });
  }, [documentList, documentType]);

  // Refs to store file input references
  const fileInputRefs = React.useRef(
    Array(documentType?.length).fill(null)
  ).current;

  // Function to handle password input
  const handlePasswordChange = (password, index) => {
    setPasswords((prev) => ({
      ...prev,
      [index]: password,
    }));
    setIsDirtyField(true);
  };

  // Function to handle file upload
  const handleFileChange = async (event, index) => {
    try {
      const file = event.target.files[0];
      const formData: any = new FormData();
      formData.append("file", file);

      setFileLoading((prev) => {
        const newState = [...prev];
        newState[index] = true;
        return newState;
      });

      const response: any = await api.post("/v1/FileUpload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response && response.status === "success") {
        setSelectedFiles((prev) => ({
          ...prev,
          [index]: response?.result,
        }));
        setFileNames((prev) => ({
          ...prev,
          [index]: file.name, // Store the file name
        }));
        setIsDirtyField(true);
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error here, such as showing an error message to the user
      dispatch(showMessage({ message: error.message, variant: "error" }));
    } finally {
      setFileLoading((prev) => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });
    }
  };

  const uploadFile = async (index) => {
    const selectedFile = selectedFiles[index];
    const document = documentType[index];
    const password = passwords[index];
    const request_data: Record<string, any> = {
      tenantId: tenantId,
      financeApplicationId: financeId || financeApplicationId,
      documentTypeId: document.id,
      documentFilePath: selectedFile,
      password: password,
    };
    // Set documentName only if document type is 'Other'
    if (document?.slug?.toLowerCase() === "other" && otherDocumentName) {
      request_data.documentName = otherDocumentName;
      setIsForEditDocumentNameChange(false);
    }
    setUploadLoading((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });

    try {
      let response;
      // Check if the document already exists
      const existingDocument = documentList.find(
        (doc) => doc.documentTypeId === document.id
      );

      if (
        (document?.slug?.toLowerCase() === "other" &&
          !isEmpty(isOtherDocumentAdded) &&
          isOtherDocumentAdded.documentId) ||
        existingDocument
      ) {
        // Update the existing document
        const update_data: Record<string, any> = {
          id:
            document?.slug?.toLowerCase() === "other" &&
            !isEmpty(isOtherDocumentAdded)
              ? isOtherDocumentAdded.documentId // Use this if "other" and `isOtherDocumentAdded` is not empty
              : existingDocument.id, // Otherwise, use `existingDocument.id`
          documentTypeId: document.id,
          password: password,
        };
        if (!!selectedFile) {
          update_data.documentFilePath = selectedFile;
        }
        // Set documentName only if document type is 'Other'
        if (document?.slug?.toLowerCase() === "other" && otherDocumentName) {
          update_data.documentName = otherDocumentName;
        }
        await api.put(`/v1/UserDocuments/UpdateUserDocument`, update_data);

        response = await api.get(`/v1/UserDocuments/${update_data.id}`);
      } else {
        // Add a new document
        response = await api.post(`/v1/UserDocuments`, request_data);
      }

      if (response.status === "success") {
        if (
          !existingDocument &&
          document?.slug?.toLowerCase() === "other" &&
          otherDocumentName &&
          isEmpty(isOtherDocumentAdded)
        ) {
          setIsOtherDocumentPath(true);
          setIsOtherDocumentAdded(response.result);
        }
        dispatch(getUserDocumentForFinanceForm(financeId));
        const updatedMissingDocs = missingDocuments.filter(
          (doc) => doc !== document.name
        );
        setMissingDocuments(updatedMissingDocs);

        setFilePaths((prev) => ({
          ...prev,
          [index]: response?.result?.documentFilePath, // Set document path for preview
        }));

        setUploads((prev) => ({
          ...prev,
          [index]: true, // Set upload status after successful response
        }));

        dispatch(
          showMessage({
            message: "Document Added Successfully",
            variant: "success",
          })
        );

        setSelectedFiles((prev) => ({
          ...prev,
          [index]: null,
        }));
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
    } finally {
      setUploadLoading((prev) => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });
    }
  };

  // Function to handle file download
  const handleDownload = (index) => {
    // Retrieve the file path using the index
    const filePath = filePaths[index];

    // Check if the file path exists
    if (filePath) {
      try {
        window.open(filePath, "_blank");
      } catch (error) {
        console.error("Error opening download link:", error);
      }
    } else {
      dispatch(showMessage({ message: t("PREVIEW_ALERT"), variant: "info" }));
    }
  };

  const handleSubmit = () => {
    const missingDocs = documentType
      .filter((doc, index) => doc.require && !uploads[index]) // Check against uploads
      .map((doc) => doc.name);
    if (missingDocs.length === 0) {
      setMissingDocuments([]);
      dispatch(setIsFinalSubmitSignatureDialogOpen(true));
    } else {
      setShowRequireDocsAlert(true);
      setMissingDocuments(missingDocs);
    }
  };

  const handleNext = async () => {
    handleSubmit();
  };

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };

  const handleSave = () => {
    if (isEdit) {
      dispatch(setIsDocumentDialogOpen(false));
      dispatch(getApplicationDetails(financeApplicationId));
    }
  };

  const filteredDocumentType = isRequired
    ? documentType.filter((doc) => doc.require)
    : documentType;

  // Handler function to capture the text input value
  const handleOtherDocumentNameChange = (event) => {
    const value = event.target.value; // Safely accessing the value
    setOtherDocumentName(value); // Updating the state with the input value
    if (!!value) {
      setIsForEditDocumentNameChange(true);
    } else {
      setIsForEditDocumentNameChange(false);
    }
  };

  return (
    <>
      <TableContainer className="mt-20 mb-20">
        <StyledTypography variant="body1" className="mb-16">
          {t("UPLOAD_DOCUMENTS")}
        </StyledTypography>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableHeaderCell>{t("DOCUMENT")}</CustomTableHeaderCell>
              <CustomTableHeaderCell>
                {t("SELECT_FILE")}
                <StyledTypographyGrey
                  variant="body2"
                  className="text-12 font-400 italic"
                >
                  ({t("SUPPORT_FILE")}: {acceptFileTypeImageAndPDF})
                </StyledTypographyGrey>
              </CustomTableHeaderCell>
              <CustomTableHeaderCell>{t("UPLOAD")}</CustomTableHeaderCell>
              <CustomTableHeaderCell>{t("PASSWORD")}</CustomTableHeaderCell>
              <CustomTableHeaderCell>{t("PREVIEW")}</CustomTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDocumentType?.map((document, index) => (
              <TableRow key={index}>
                <CustomTableBodyCell>
                  {document?.slug?.toLowerCase() === "other" ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      placeholder={t("OTHER_DOCUMENT")}
                      value={otherDocumentName || ""}
                      onChange={handleOtherDocumentNameChange}
                      inputProps={{
                        "data-test-id":"other-document-text-field"
                      }}
                    />
                  ) : document.require ? (
                    `${document.name} *`
                  ) : (
                    document.name
                  )}
                </CustomTableBodyCell>

                <CustomTableBodyCell className="max-w-60">
                  <div className="flex flex-col gap-1 ">
                    <input 
                      type="file"
                      accept={acceptFileTypeImageAndPDF}
                      hidden
                      ref={(ref) => (fileInputRefs[index] = ref)}
                      onChange={(e) => handleFileChange(e, index)}
                      // data-test-id={`select-${document.name.replace(/[^a-zA-Z0-9-]/g, '')}-document`} // remove space from doc name and assign data test id
                      data-test-id={`select-${index}-document`}
                      />
                    <StyledButtonForOutlined
                      variant="outlined"
                      onClick={() => fileInputRefs[index].click()}
                    >
                      {fileLoading[index] ? (
                        <CircularProgress
                          size={24}
                          color="secondary"
                          className="button-progress"
                        />
                      ) : (
                        t("SELECT_FILE")
                      )}
                    </StyledButtonForOutlined>
                    {fileNames[index] && (
                      <Typography
                        className="break-words w-full whitespace-pre-wrap leading-none"
                        variant="caption"
                      >
                        {fileNames[index]}
                      </Typography>
                    )}
                  </div>
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  <StyledButtonForOutlined
                    variant="outlined"
                    onClick={() => uploadFile(index)}
                    data-test-id={`upload-document-${index}-btn`}
                    disabled={
                      document?.slug?.toLowerCase() === "other"
                        ? !isOtherDocumentPath
                          ? !(
                              isForEditDocumentNameChange &&
                              selectedFiles[index]
                            ) // Disable if no path, and both name and file are required
                          : !(
                              isForEditDocumentNameChange ||
                              selectedFiles[index]
                            ) // Disable if path exists, and both name and file must change to enable button
                        : !selectedFiles[index] // For other document types, only file is required
                    }
                  >
                    {uploadLoading[index] ? (
                      <CircularProgress
                        size={24}
                        color="secondary"
                        className="button-progress"
                      />
                    ) : uploads[index] &&
                      (document?.slug?.toLowerCase() === "other"
                        ? !isOtherDocumentPath
                          ? !(
                              isForEditDocumentNameChange &&
                              selectedFiles[index]
                            ) // Disable if no path, and both name and file are required
                          : !(
                              isForEditDocumentNameChange ||
                              selectedFiles[index]
                            ) // Disable if path exists, and both name and file must change to enable button
                        : !selectedFiles[index]) ? (
                      t("UPLOADED")
                    ) : (
                      t("UPLOAD")
                    )}
                  </StyledButtonForOutlined>
                </CustomTableBodyCell>
                <TableCell className="text-center p-0 border-1">
                  <StyledTextField
                    variant="outlined"
                    type="password"
                    size="small"
                    placeholder="*****"
                    onChange={(e) => handlePasswordChange(e, index)}
                  />
                </TableCell>
                <CustomTableBodyCell onClick={() => handleDownload(index)}>
                  <Typography
                    variant="body1"
                    color={themesConfig.default.palette.secondary.main}
                    className="cursor-pointer"
                  >
                    {t("PREVIEW")}
                  </Typography>
                </CustomTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MissingDocumentsAlert
        documentTypes={documentType}
        documentList={documentList}
        showRequireDocsAlert={showRequireDocsAlert}
        setShowRequireDocsAlert={setShowRequireDocsAlert}
        t={t}
      />
      <Box>
        <Box className="flex justify-end items-end gap-20 mt-10">
          {isEdit ? (
            <ColoredSubmitButton
              className="w-120"
              text={t("SAVE")}
              onClick={handleSave}
              data-test-id="submit-documents-btn"
            ></ColoredSubmitButton>
          ) : (
            <>
              <Button
                variant="outlined"
                className="capitalize w-120"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {t("PREVIOUS")}
              </Button>
              <ColoredSubmitButton
                text={t("SUBMIT")}
                className="w-120"
                onClick={handleNext}
                data-test-id="submit-documents-btn"
              ></ColoredSubmitButton>
            </>
          )}
        </Box>
        <FinalApplicationSubmit
          applicationId={financeId || financeApplicationId}
          phoneNumber={
            applicationData?.userAdditionalInformation?.phoneNumber ||
            userData?.phoneNumber
          }
        />
      </Box>
    </>
  );
};

export default UploadDocument;
