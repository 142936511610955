import { selectFeaturePermission } from "app/store/user/userSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import history from "@history";
import { showMessage } from "app/store/fuse/messageSlice";

export function formatAmount(num) {
  if (typeof num !== "number" || isNaN(num)) {
    return "RM 00.00";
  }
  return `RM ${new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(num)}`;
}

export function formatDate(
  dateString: string,
  outputFormat: string = "DD/MMM/YYYY",
  inputFormat?: string
): string {
  const date = inputFormat
    ? moment(dateString, inputFormat)
    : moment(dateString);

  if (!date.isValid()) {
    return "";
  }

  return date.format(outputFormat);
}

export const calculateMinimumDOBForAdult = () => {
  return moment().subtract(18, "years").toDate(); // Subtract 18 years from the current date
};

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber) return `+60 ${phoneNumber}`;
  return "--";
}

export function hasPermission(permission) {
  const [feature, action] = permission.split(":");
  const featurePermissions = useSelector(selectFeaturePermission);

  if (!featurePermissions) {
    return false;
  }

  return featurePermissions.some(
    (permission) =>
      permission.roleFeatureName === feature &&
      permission.name === action &&
      permission.value
  );
}

export function decodeUrlAndGetFileType(url) {
  try {
    const urlParts = url.split("/");
    const fileName = urlParts[urlParts.length - 1].split("?")[0];
    const fileExtension = fileName.split(".").pop();
    return fileExtension;
  } catch (error) {
    console.error("Error decoding URL:", error);
    return null;
  }
}

export const notificationRedirect = (notificationDetail) => {
  const meta = notificationDetail.meta;
  if (!meta) return;
  switch (meta.event) {
    case "Communication":
      history.push(`/user/my-application/${meta.applicationId}?tab=2`);
      break;
    case "SubmitFinanceApplication":
      history.push(`/user/my-application/${meta.applicationId}`);
      break;
    case "ExternaleRemark":
      history.push(`/user/my-application/${meta.applicationId}`);
      break;
    default:
      break;
  }
};

export const removeLeadingZero = (value: string): string => {
  return value.startsWith("0") ? value.substring(1) : value;
};

export const differenceBetweenTwoDateInYear = (
  startDate: Date,
  endDate: Date
) => {
  const timeDiff = endDate?.getTime() - startDate?.getTime();
  const yearDiff = timeDiff / (1000 * 60 * 60 * 24 * 365.25);
  return yearDiff;
};

export const formatTime = (milliseconds: number) => {
  const mins = Math.floor(milliseconds / 60000);
  const secs = Math.floor((milliseconds % 60000) / 1000);
  return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

export const formatDateForForm = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

// Page permission check

export const pageFilter = (pages, slug) => {
  // Filter pages based on slug
  const slugIncludedPages = pages?.filter((page) => page.slug.includes(slug));

  // Filter the pages to always show "DASHBOARD" and check permissions for others
  const finalPageList = slugIncludedPages?.filter(
    (page) => page.title === "DASHBOARD" || hasPermission(page.permission)
  );

  return finalPageList;
};

// Copy text to clip board
export const copyToClipBoard = (copyText, t, dispatch) => {
  !!copyText
    ? navigator.clipboard.writeText(copyText).then(
        () => {
          dispatch(
            showMessage({
              message: t("COPY_TEXT"),
              variant: "success",
              autoHideDuration: 5000,
            })
          );
        },
        (err) => {
          console.error("FAIL_COPY", err);
        }
      )
    : dispatch(
        showMessage({
          message: t("FAIL_COPY"),
          variant: "error",
          autoHideDuration: 5000,
        })
      );
};

export const showFinanceApplicationDownloadPdf = (applicationDetailsData) => {
  const allowedStatuses = [
    "disbursement",
    "settled",
    "active",
    "pending",
    "rejected",
    "draft",
  ];
  // Return true if isApproved is true and the status is not in the allowed list
  return (
    applicationDetailsData.isApproved &&
    (!applicationDetailsData.financeApplicationStatus ||
      !allowedStatuses.includes(
        applicationDetailsData.financeApplicationStatus.toLowerCase()
      ))
  );
};

export const openFileInWindow = (fileURL) => {
  window.open(fileURL, "_blank");
};

export const buildQueryParams = (params: Record<string, any>): string => {
  return Object.entries(params)
    .filter(([_, value]) => !!value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

// description: Truncate text to a specific length
export function truncateText(text: string, maxLength: number): string {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
}

export function setLocalStorageItem(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: string): string | null {
  return localStorage.getItem(key);
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key);
}
