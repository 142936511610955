import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useInputStyles } from "../Styles/CustomStyle";

const CustomTextField = ({
  label = null,
  onChange = (value) => {},
  variant = "filled",
  error = "",
  isUpperCase = true,
  ...props
}) => {
  const classes = useInputStyles();

  const handleChange = (event) => {
    let newValue = event.target.value;
    if (newValue.startsWith(" ")) {
      return;
    }
    // Replace multiple spaces with a single space
    newValue = newValue.replace(/\s+/g, " ");
    onChange(newValue);
  };

  const handleBlur = (event) => {
    const value = event.target.value;
    if (isUpperCase) {
      onChange(value.toUpperCase());
    } else {
      onChange(value);
    }
  };

  return (
    <TextField
      label={label}
      variant={variant as "standard" | "filled" | "outlined"}
      onChange={handleChange}
      inputProps={{
        style: { textTransform: isUpperCase ? "uppercase" : "none" },
      }}
      className={classes.removeNumberInputSpinner}
      onBlur={handleBlur}
      error={!!error}
      helperText={error}
      {...props}
    />
  );
};

export default CustomTextField;
