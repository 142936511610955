const locale = {
  LOG_IN: "Log In",
  SIGN_OUT: "Sign out",
  WELCOME: "Welcome,",
  DASHBOARD: "Dashboard",
  PERSONAL_INFO: "Personal Info",
  ADDITIONAL_INFORMATION: "Additional Information",
  CONTACT: "Contact",
  ADDRESS_INFO: "Address Information",
  MY_APPLICATION: "My Applications",
  MESSENGER: "Chat",
  MEMBER: "Member",
  APPLY_FOR_FINANCE: "Apply For Finance",
  REGISTER: "Register Member",
  DOCUMENTS: "Documents",
  SEARCH_FIELD_LABEL: "Search Your Application",
  UPLOAD_DOCUMENT: "Upload Document",
  CANCEL: "Cancel",
  DOCUMENT_TYPE: "Document Type",
  ERROR_DOCUMENT: "Document is required",
  ERROR_DOCUMENT_TYPE: "Select Document Type",
  ADDRESS: "Address",
  DETAILS_0F_SPOUSE: "Details of Spouse",
  DETAILS_0F_REFERRAL: "Family Reference (Not living together)",
  DETAILS_0F_NEXT_OF_KIN: "Details of Next of kin",
  CONTACT_DETAILS: " Contact Details",
  APPLICATION_ID: "Application Id",
  FINANCE_PRODUCT: "Finance Product",
  CATEGORY_NAME: "Category Name",
  CREATED_DATE: "Created Date",
  AMOUNT: "Amount",
  TITLE: "Title",
  NAME: "Name",
  OTHER_DETAILS: "Other Details",
  MOTHER_FULL_NAME: "Mother Full Name",
  JOB_TYPE: "Job Type",
  JOB_UNIQUE_CODE: "Position",
  MEMBERSHIP_NUMBER: "Membership Number",
  DATE_OF_BIRTH: "Date of Birth",
  SEX: "Sex",
  STATUS: "Status",
  NATIONAL_ID: "New IC No",
  NATIONAL_ID_OR_PASSPORT: "New IC No / Passport",
  OTHER_ID: "Other ID No",
  RACE: "Race",
  RELIGION: "Religion",
  QUALIFICATION: "Qualification",
  HIGHEST_QUALIFICATION: "Highest Qualification",
  HOUSE_ADDRESS: "Residential Address",
  POST_CODE: "Postcode",
  CITY: "City",
  STATE: "State",
  HOUSE_STATUS: "House Status",
  MOBILE_NUMBER: "Mobile Number",
  DEFAULT_LANGUAGE: "Default Language",
  HOME_PHONE_NUMBER: "Home Phone Number",
  PERSONAL_EMAIL: "Personal Email",
  WORK_EMAIL: "Work Email",
  MAILING_ADDRESS: "Mailing Address",
  MAILING_CITY: "Mailing City",
  MAILING_STATE: "Mailing State",
  MAILING_CONTACT_DETAILS: "Mailing Contact Details",
  SIBLING_NAME: "Sibling Name",
  RESIDENTIAL_LEVEL: "Residential Ownership Type",
  OWN: "Own",
  RENT: "Rented",
  PARENTS: "Parents",
  FATHER_OWN: "Father's Own",
  RELATIONSHIP: "Relationship",
  BROTHER: "Brother",
  OTHER: "Other",
  EMAIL: "Email",
  HP_NO: "HP No",
  HOUSE_NUMBER: "House Number",
  HOUSE_CITY: "House City",
  HOUSE_STATE: "House State",
  SAME_ADDRESS: "Is Mailing address same as Residential Address?",
  EMPLOYMENT_DETAILS: "Employment Details",
  EMPLOYER_NAME: "Employer Name",
  DEPARTMENT: "Department",
  JOB: "Job",
  GRADE: "Grade",
  EMPLOYMENT_STATUS: "Employment Status",
  EMPLOYMENT_DATE: "Employment Date:",
  RETIREMENT_AGE: "Retirement Option",
  START_DATE_OF_WORK: "Start Date Of Work",
  END_DATE_OF_WORK: "End Date Of Work",
  PERIOD_OF_SERVICE: "Period of Service",
  OFFICE_ADDRESS: "Office Address",
  OFFICE_CITY: "Office City",
  OFFICE_STATE: "Office State",
  OFFICE_PHONE: "Office Phone",
  INCOME_DETAILS: "Income Details",
  SALARY_DETAIL: "Salary Details",
  BASIC_SALARY: "Basic Salary",
  FIXED_ALLOWANCES: "Fixed Allowances",
  TOTAL_DEDUCTION: "Total Deduction",
  SALARY_INCREMENT: "Salary Increment",
  NET_SALARY: "Net Salary",
  DATE_OF_INCREMENT: "Date of Increment",
  REMARKS: "Remarks",
  BANK_DETAILS: "Banking Details",
  NAME_OF_BANK: "Name Of Bank",
  ACCOUNT_NUMBER: "Account Number",
  BANK_BRANCH: "Bank Branch",
  TYPE_OF_ACCOUNT: "Type of Account",
  FINANCE_DETAILS: "Financing Details",
  AMOUNT_OF_FINANCING: "Amount Of Financing",
  TENURE_OF_APPLICATION: "Tenure Of Application",
  INTEREST_RATE: "Profit Rate(%)",
  APPLICATION_NUMBER: "Application Number",
  MEMBERSHIP_FEES: "Membership Fees",
  REGISTRATION_FEES: "Registration Fees",
  PRODUCT_DETAILS: "Product Details",
  PRODUCT_NAME: "Product Name",
  PRODUCT_VARIANT_NAME: "Product Variant",
  CATEGORY: "Category",
  DURATION_MONTH: "Duration Month",
  LOAN_RATE_TYPE: "Loan Rate Type",
  DESCRIPTION: "Description",
  ROW_NUMBER: "Number Of Row",
  FINANCE_NAME: "Name Of Financial Institution",
  LOAN_BALANCE: "Balance of Finance",
  MONTHLY_COMMITMENTS: "Monthly Repayment",
  LOAN_TENURE: "Balance Financing Tenure(Months)",
  SETTLEMENT_LETTER: "Do you have a Settlement Letter?",
  VALIDITY_PERIOD: "Validity Period",
  FINANCE_FILE: "Uploaded file",
  SUBMITTED: "Submitted",
  UNDER_REVIEW: "Under Review",
  APPROVED: "Approved",
  PROCESSING: "Processing",
  DISBURSED: "Disbursed",
  CLOSED: "Closed",
  ADD_DOCUMENT: "+ Add Document",
  COMMENTS: " Comments",
  SUBMIT: "Submit",
  UPLOADED_BY: "Uploaded By:",
  UPLOADED_ON: "Uploaded On:",
  CREATED_AT: "Created At",
  ACTIONS: "Actions",
  EDIT: "Edit",
  EDIT_PERSONAL_INFO: "Edit Personal Info",
  EDIT_ADDITIONAL_INFO: "Edit Additional Info",
  EDIT_ADDRESS: "Edit Address",
  DELETE: "Delete",
  CONFORM_DELETE: "Are you sure you want to delete this document?",
  DELETE_DOCUMENT: "Delete Document",
  CHOOSE_FILE: "Choose File",
  ADD_MEMBER: "Add Member",
  SAVE: "Save",
  SAVE_AND_CONTINUE: "Save & Continue",
  FINISH: "Finish",
  CLOSE: "Close",
  NO_FINANCE: "No existing finance",
  USER_ADDITIONAL_SECTION_REMARK: "User additional information remark",
  USER_ADDRESS_SECTION_REMARK: "Address detail remark",
  USER_RELATIVE_SECTION_REMARK: "Relative detail remark",
  USER_EMPLOYMENT_SECTION_REMARK: "Employment detail remark",
  USER_INCOME_SECTION_REMARK: "Income detail remark",
  USER_FINANCE_SECTION_REMARK: "User finance detail remark",
  USER_BANK_SECTION_REMARK: "Bank detail remark",
  USER_DOCUMENT_SECTION_REMARK: "Document detail remark",
  NO_REMARK: "No remarks found for this section.",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  NO_COMMENTS: "No Comment Found",
  MINI_AGENT: "Regional Manager",
  ADD_MINI_AGENT: "Add Regional Manager",
  CONFIRM_SUBMIT_APPLICATION_TITLE: "Confirm Submission",
  CONFIRM_SUBMIT_APPLICATION_MESSAGE:
    "Are you sure you want to submit your application?",
  FINAL_SUBMIT_TOOLTIP:
    "Please fill all required details before submitting application.",
  CHANGE_DETECT_TITLE: "Are you sure want discard changes?",
  CHANGE_DETECT_BUTTON: "Keep Editing",
  ARMY: "Army",
  POLICE: "Police",
  MALE: "Male",
  FEMALE: "Female",
  MR: "Mr.",
  MRS: "Mrs.",
  MISS: "Miss.",
  NO_DOCUMENT: " No documents found.",
  DOWNLOAD: "Download",
  DETAILS: "Details",
  COMMUNICATION: "Communication",
  MEMBER_DETAILS: "Member Details",
  MINI_AGENT_DETAIL: "Regional Manager Detail",
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  DIPLOMA: "Diploma",
  DEGREE: "Degree",
  MASTERS: "Masters",
  DOCTORATE_DEGREE: "Doctorate Degree",
  PROFESSOR: "Professor",
  AGENT_CODE: "Agency/Manager Code",
  DISCARD: "Discard",
  MEMBER_NAME: "Member Name",

  // Validation  messages
  TITLE_REQUIRED_VALIDATION: "Title is required.",
  FULLNAME_REQUIRED_VALIDATION: "Full name is required.",
  EMAIL_VALIDATION: "Please enter a valid email address.",
  PHONE_NUMBER_INVALID_VALIDATION: "Phone number is required.",
  PHONE_NUMBER_INVALID: "Please  provide valid phone number.",
  DEFAULT_LANGUAGE_REQUIRED_VALIDATION: "Default language is required.",
  SEX_REQUIRED_VALIDATION: "Please Select Sex",
  JOB_TYPE_REQUIRED_VALIDATION: "Please Select Job Type",
  NATIONAL_ID_REQUIRED_VALIDATION: "You must enter your New IC No.",
  RELIGION_REQUIRED_VALIDATION: "Enter religion",
  MOTHER_FULL_NAME_REQUIRED_VALIDATION: "Mother Name Is Required",
  RACE_REQUIRED_VALIDATION: "Race Is required",
  JOB_UNIQUE_CODE_REQUIRED_VALIDATION: "Enter Position",
  MEMBERSHIP_NUMBER_INVALID_VALIDATION: "Enter Membership Number",
  HIGHEST_QUALIFICATION_REQUIRED_VALIDATION:
    "Provide Your Highest Qualification",
  HOUSE_ADDRESS_VALIDATION: "Please enter Residential Address",
  POSTCODE_VALIDATION: "Postcode is required",
  CITY_VALIDATION: "City name is required",
  STATE_VALIDATION: "State is required",
  MAILING_ADDRESS_VALIDATION: "Mailing address is required",
  MAILING_POSTCODE_VALIDATION: "Mailing postcode is required",
  MAILING_CITY_VALIDATION: "Mailing city name is required",
  MAILING_STATE_VALIDATION: "Mailing state is required",
  HOUSE_STATUS_REQUIRED_VALIDATION: "House  Status is required",
  PASSWORD_VALIDATION_REQUIRED: "Please enter your password.",
  PASSWORD_VALIDATION_MIN: "Password is too short - should be 8 chars minimum.",
  CONFIRM_PASSWORD_VALIDATION_MATCH: "Passwords must match",
  OTHER_VALUE_VALIDATION: "Please Enter Residential Ownership Type",
  REQUIRED: "Required",
  JOB_UNIQUE_CODE_VALIDATION: "Job  Position",
  DOCUMENT_SIGNED: "Document Signature",

  SERIAL_NO: "No.",
  SUPPORT_FILE: "Supported files",
  DOWNLOAD_PDF: "Download PDF",
  ADD_ROW: "Add Row",
  DELETE_EXISTING_FINANCE_TITLE: "Confirm",
  DELETE_EXISTING_FINANCE_DESCRIPTION: "Are you sure you want to delete?",
  SEND: "Send",

  // eKYC DATA
  KYC_COMPLETE: "eKYC Verification Successful",
  START_KYC: "Start KYC",
  FONT_BACK_IC: "For Front and Back MyTentera",
  SELFIE: "Owner Selfie",
  SELFIE_VALIDATION: "Selfie is required",
  KYC_RESULT: "KYC Result",
  CAMERA_NOT_SUPPORTED: "Camera not supported or not available on this device.",
  KYC_PROCESS: "Process KYC",
  KYC_DIS: "Please follow the steps to complete your KYC verification.",
  FRONT_IC: "Front MyTentera",
  FONT_IC_VALIDATION: "Front MyTentera is required.",
  BACK_IC_VALIDATION: "Back MyTentera is required",
  BACK_IC: "Back MyTentera",
  CONFORM_SUBMISSION: "Confirm Submission",
  CONFIRM_SUBMISSION_DIC:
    "Are you sure you want to proceed with the submitted images?",
  NEXT: "Next",
  PROCEED: "Proceed",
  RETAKE: "Retake",
  USE_PHOTO: "Use Photo",
  CAPTURE: "Capture",
  WAIT_MESSAGE: "Please wait, we are processing your KYC.",
  WAIT_DIC:
    "This may take a few moments. Do not refresh the page and hold on while we verify your details.",
  KYC_SUCCESS: "KYC Verification Successful!",
  KYC_FAILED: "KYC Verification Failed.",
  KYC_SUCCESS_DIC:
    "Your KYC has been verified. You may proceed to use the application.",
  KYC_FAILED_DIC:
    "Unfortunately, your KYC verification was not successful. Please check your details and try again.",
  RETRY: "Retry",
  VIEW_DETAILS: "View Detail",
  REJECTED_FINANCE_TOOLTIP: "Rejected finance application data can't be edited",
  HOW_IT_WORK: "How it Works?",
  IDENTITY: "It only takes 3 captures to identify your identity.",
  DOS: "Do",
  DONTS: "Don'ts",
  DO_AND_DONT_FOR_FONT_BACK_BEST: [
    {
      do: "Make sure your document is clear, with no blurriness, glare, or shadows.",
      dont: "Avoid covering any part of the document with your fingers or other objects.",
    },
    {
      do: "Align your document so it fills the entire frame, with all edges visible.",
      dont: "Avoid taking photos in dimly lit or overly bright areas that may obscure the details of your document.",
    },
    {
      do: "Capture your document in a well-lit area.",
      dont: "Ensure the document is flat and properly aligned within the frame.",
    },
  ],
  DO_AND_DONT_FOR_IMAGE_BEST: [
    {
      do: "Ensure your entire face is visible in the frame, with no obstructions like sunglasses, hats, or masks.",
      dont: "Avoid using any filters or editing tools on your selfie. Submit a natural photo.",
    },
    {
      do: "Keep a neutral expression, with your eyes open and mouth closed, similar to a passport photo.",
      dont: "Avoid covering your face with hair, hands, or accessories like scarves that could obstruct your identity.",
    },
    {
      do: "Take your selfie in a well-lit area with a plain background for clear visibility.",
      dont: "If your device lacks a camera, do not proceed with the KYC process until you have access to a suitable device.",
    },
    {
      do: "Make sure your device's camera is working and clean to avoid blurry images.",
      dont: "Avoid uploading photos that don’t meet the required clarity or visibility standards. Switch to a camera-enabled device instead.",
    },
  ],
  NO_CAMERA_MESSAGE:
    "It seems that your device does not have a camera or the camera is not accessible. To complete the KYC process, you can copy the link below and access this page from a device with a working camera.",
  COPY_LINK: "Copy Link",
  LINK_COPIED:
    "The link has been copied to your clipboard. You can now paste it into a browser on another device to continue the KYC process.",
  FONT_BACK_NOTE:
    "Note: Please ensure the entire ID is visible, not cropped, and in focus.",
  COMPLETE_KYC_QR_TITLE: "Complete Your KYC Process",
  COMPLETE_KYC_QR_DES:
    "Scan the QR code below using your mobile device to complete the KYC process.",
  KYC_LINK: "KYC Link",
  TIME_REMAINING: "Time remaining",
  FRONT_TITLE_MESSAGE: "Align the front of your MYTentera ID with the frame.",
  FRONT_PREVIEW_MESSAGE: "Ensure the photo is clear without any glare or blur.",
  BACK_TITLE_MESSAGE: "Align the back of your MYTentera ID with the frame.",
  BACK_PREVIEW_MESSAGE:
    "Ensure the captured photo is clear without any reflections.",
  SELFIE_TITLE_MESSAGE: "Align your face within the frame for the selfie.",
  SELFIE_PREVIEW_MESSAGE: "Recording starts in {countdownSec}...",
  // eKYC LIVENESS
  GET_READY_TEXT: "Get ready for the video selfie...",
  HOLD_POSITION_TEXT: "Hold steady, capturing...",
  CENTER_FACE_TEXT: "Center your face within the frame.",
  MOVE_CLOSERtEST: "You're too far, move closer to the camera.",
  MOVE_AWAY_TEXT: "You're too close, move slightly away.",
  CAPTURE_PROCESS_ERROR:
    "An error occurred during the capture process. Please try again.",
  SDK_NOT_GET_ERROR: "Your SDK token not get for KYC, Please start again!",
  SDK_ERROR: "SDK initialization failed. Please restart the process.",
  WEB_LIB_ERROR: "WAILib is not loaded. Please try again later.",
  REDIRECT_MESSAGE: "You will be redirected in {{seconds}} seconds...",

  // Referral Details
  REFERRAL_DETAILS: "Referral Details",
  REFERRAL_CODE: "Referral Code",
  REFERRAL_LINK: "Referral Link",
  REFERRAL_INSTRUCTIONS:
    "Scan the QR Code below to register using the Agency code",
  COPY_TEXT: "Copied to clipboard.",
  FAIL_COPY: "Failed to copy",
  MARITAL_STATUS: "Marital Status",

  // standart validation messages
  TEXT_FIELD_REQUIRED_VALIDATION: "{{fieldName}} is required",
  SELECT_FIELD_REQUIRED: "Please select a valid option",
  MINIMUM_VALIDATION: "{{fieldName}} must be greater than or equal to  {{min}}",
  MAXIMUM_VALIDATION: "{{fieldName}} must be less than or equal to  {{max}}",

  FULL_NAME: "Full Name",
  DOCUMENT_NAME: "Document Name",
  VERIFY_OTP: "Verification",
  REQUIRED_DOCUMENT: "Please Upload Required documents",
  MANAGER_CODE: "Manager Code",
  OUTSTANDING_AMOUNT: "Outstanding Amount",

  // SIGNATURE
  CUSTOMER_SINGED: "Customer Sign",
  ADMIN_SINGED: "Admin Sign",
  LAWYER_SINGED: "Lawyer Sign",
  FORM_J_PDF: "Form J PDF",

  FINANCE_APPLICATION: "Finance Applications"
};

export default locale;
