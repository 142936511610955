import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";

interface PDFPreviewForTheSignatureProps {
  filePath: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  isButtonLoading?: boolean;
  bottomButtonLabel?: string;
  applicationDetailsData: Record<any, any>;
}

const PDFPreviewForTheSignature: React.FC<PDFPreviewForTheSignatureProps> = ({
  filePath,
  open,
  onClose,
  onConfirm,
  isButtonLoading = false,
  bottomButtonLabel,
  applicationDetailsData
}) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent className="p-0 h-[80vh]">
        <embed
          src={filePath}
          type="application/pdf"
          width="100%"
          height="100%"
        />
        {!applicationDetailsData?.digitalSignatureDetails?.isCustomerSinged && (
          <div className="sticky bottom-0 bg-white w-full border-t-1 border-solid border-grey-500 px-10 py-10 text-end">
            <ColoredSubmitButton
              variant="contained"
              disabled={isButtonLoading}
              onClick={onConfirm}
              isLoading={isButtonLoading}
              text={bottomButtonLabel}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

PDFPreviewForTheSignature.propTypes = {
  filePath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isButtonLoading: PropTypes.bool,
  bottomButtonLabel: PropTypes.string,
};

export default PDFPreviewForTheSignature;
