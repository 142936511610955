import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { formatAmount } from "src/app/services/utils";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getIsProductDetailDialogOpen,
  setProductDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import ProductDetails from "src/app/main/application-form/component/ProductDetails";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import AgentDetailTooltip from "app/shared-components/Tooltip/AgentDetailTooltip";
import MemberDetailTooltip from "app/shared-components/Tooltip/MemberDetailTooltip";

const productDetailAccordion = ({ productDetail }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsProductDetailDialogOpen) || false;
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setProductDetailDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setProductDetailDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const productDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("APPLICATION_NUMBER")}
            value={productDetail?.applicationNumber}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("NAME")}
            value={
              productDetail?.userDetail ? (
                <MemberDetailTooltip memberDetail={productDetail?.userDetail} />
              ) : (
                "--"
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("MEMBERSHIP_FEES")}
            value={formatAmount(productDetail?.membershipFees)}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("REGISTRATION_FEES")}
            value={formatAmount(productDetail?.registrationFees)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("FINANCE_PRODUCT")}
            value={productDetail?.financeProductName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("CATEGORY")}
            value={productDetail?.categoryName}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("PRODUCT_NAME")}
            value={productDetail?.productName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("PRODUCT_VARIANT_NAME")}
            value={productDetail?.productVariantName}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("AMOUNT_OF_FINANCING")}
            value={formatAmount(productDetail?.financeAmount)}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("DURATION_MONTH")}
            value={productDetail?.durationMonth}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailItem
            label={t("INTEREST_RATE")}
            value={productDetail?.interestRate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailItem
            label={t("AGENT_CODE")}
            value={
              !!productDetail?.agentUser ? (
                <AgentDetailTooltip agentDetail={productDetail?.agentUser} />
              ) : (
                "--"
              )
            }
          />
        </Grid>
        {/* code commented due to incorrect data */}
        {/* <Grid item xs={12} sm={4} md={4}>
          <DetailItem
            label={t("OUTSTANDING_AMOUNT")}
            value={formatAmount(productDetail?.outstandingAmount)}
          />
        </Grid> */}
      </Grid>
    </>
  );
  return (
    <>
      <CommonAccordion
        title={t("PRODUCT_DETAILS")}
        content={productDetailContent}
        icon={false}
        handleEditOpen={handleEditSectionOpen}
        permission={FeaturePermissions.FinanceApplication.Update}
        editIcon={productDetail?.financeApplicationStatus === "Draft"}
      />
      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("PRODUCT_DETAILS"),
          component: (
            <ProductDetails
              financeId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default productDetailAccordion;
