const locale = {
  FORM_TITLE: "Borang Permohonan Pembiayaan",
  PERSONAL_INFO: "Maklumat Peribadi",
  ADDRESS_INFO: "Maklumat Alamat",
  SIBLINGS_DETAILS: "Butiran Pasangan",
  REFERRAL_DETAILS: "Rujukan Keluarga (Tidak tinggal bersama)",
  NEXT_OF_KIN_DETAILS: "Butiran waris",
  EMPLOYMENT_DETAILS: "Butiran Pekerjaan",
  INCOME_DETAILS: "Butiran Pendapatan",
  EXISTING_LOAN: "Penyelesaian Pinjaman Sedia Ada (Jika Ada)",
  BANK_DETAILS: "Butiran Bank",
  PRODUCT_DETAILS: "Butiran Produk",
  UPLOAD_DOCUMENTS: "Muat Naik Dokumen",
  NEXT: "Seterusnya",
  PREVIOUS: "Sebelumnya",
  SUBMIT: "Hantar",
  FAMILY_REFERRAL: "Rujukan Keluarga",
  SPOUSE: "Pasangan",
  TITLE: "Gelaran",
  FULL_NAME: "Nama Penuh",
  SEX: "Jantina",
  DOB: "Tarikh Lahir",
  AGE: "Umur",
  MOTHER_FULL_NAME: "Nama Ibu",
  JOB_TYPE: "Butiran Pekerjaan",
  JOB_UNIQUE_CODE: "Jawatan",
  MEMBER_LIST: "Pilih Ahli",
  MEMBERSHIP_NUMBER: "Nombor Keahlian",
  OTHER_ID: "No ID Lain",
  NATIONAL_ID: "No IC Baru",
  NATIONAL_ID_OR_PASSPORT: "No IC / Pasport Baru",
  RELIGION: "Agama",
  CHILD: "Anak",
  RACE: "Bangsa",
  STATUS: "Status",
  DEPENDENCY: "Tanggungan",
  CONTACT_DETAILS: "Butiran Hubungan",
  HOME_PHONE: "Nombor Telefon Rumah",
  MOBILE_PHONE: "Nombor Telefon Bimbit",
  PERSONAL_EMAIL: "Emel Peribadi",
  WORK_EMAIL: "Emel Kerja",
  QUALIFICATION: "Kelayakan",
  HIGHEST_QUALIFICATION: "Kelayakan Tertinggi",
  HOUSE_ADDRESS: "Alamat Rumah",
  POST_CODE: "Poskod",
  CITY: "Bandar",
  STATE: "Negeri",
  SAME_ADDRESS: "Adakah alamat surat sama dengan alamat rumah?",
  SAME_AS_MY_ADDRESS: "Adakah alamat anda sama dengan alamat rumah anda?",
  MAILING_ADDRESS: "Alamat Surat-menyurat",
  RESIDENTIAL_LEVEL: "Jenis Pemilikan Kediaman",
  OWN: "Sendiri",
  RENT: "Sewa",
  PARENTS: "Ibu Bapa",
  FATHER_OWN: "Milik Bapa",
  RELATIONSHIP: "Hubungan",
  BROTHER: "Abang",
  OTHER: "Lain-lain",
  HUSBAND: "Suami",
  WIFE: "Isteri",
  MALE: "jantan",
  FEMALE: "perempuan",
  MR: "Encik.",
  MRS: "Puan.",
  MISS: "Cik.",
  SAVING: "Menyimpan",
  CURRENT: "semasa",
  ARMY: "Tentera",
  POLICE: "Polis",
  DO_YOU_WANT_TO_ADD_SPOUSE_OR_SIBLINGS_DETAILS:
    "Adakah anda sudah berkahwin? Tambahkan hanya butiran pasangan mereka di sini",
  DO_YOU_WANT_TO_ADD_REFERRAL_DETAILS:
    "Adakah anda mahu menambah butiran rujukan?",
  DO_YOU_WANT_TO_ADD_NEXT_OF_KIN_DETAILS:
    "Adakah anda mahu menambah butiran waris?",
  DETAILS_OF_SPOUSE: "Butiran Pasangan",
  EMPLOYER_NAME: "Nama Majikan",
  JOB: "Jawatan",
  GRADE: "Gred",
  OFFICE_PHONE: "Telefon Pejabat",
  DEPARTMENT: "Jabatan",
  RETIREMENT_AGE: "opsyen persaraan",
  EMPLOYMENT_DATE: "Tarikh Memulakan Pekerjaan:",
  START_DATE: "Tarikh Mula Bekerja",
  END_DATE: "Tarikh Tamat Bekerja",
  SERVICE_PERIOD: "Tempoh Perkhidmatan",
  JOB_STATUS: "Status Pekerjaan",
  EXPERIMENT: "Eksperimen",
  TEMPORARY: "Sementara",
  CONTRACT: "Kontrak",
  PERMANENT: "TETAP",
  OFFICE_ADDRESS: "Alamat Pejabat",
  OTHER_DETAIL: "Butiran Lain",
  SALARY_DETAIL: "Butiran Gaji",
  BASIC_SALARY: "Gaji Asas",
  SALARY_INCREMENT: "Kenaikan Gaji",
  FIXED_ALLOWANCES: "Elaun Tetap",
  INCREMENT_DATE: "Tarikh kenaikan Gaji",
  TOTAL_DEDUCTION: "Potongan Jumlah",
  NET_SALARY: "Gaji Bersih",
  REMARK: "Ulasan (Jika Ada)",
  FINANCE_NAME: "Nama Institusi Kewangan",
  LOAN_BALANCE: "Imbangan Kewangan",
  MONTHLY_COMMITMENTS: "Bayaran Balik Bulanan",
  LOAN_TENURE: "Tempoh Pembiayaan Baki (Bulan)",
  SETTLEMENT_LETTER: "Adakah anda mempunyai Surat Penyelesaian?",
  VALIDITY_PERIOD: "Tempoh sah",
  FINANCE_FILE: "Muat naik fail",
  ADD_ROW: "Tambah Baris",
  BANK_NAME: "Nama Bank",
  BANK_BRANCH: "Cawangan Bank",
  ACCOUNT_NUMBER: "Nombor Akaun",
  ACCOUNT_TYPE: "Jenis Akaun",
  CATEGORY: "Kategori",
  SELECT_PRODUCT: "Pilih Produk",
  PRODUCT_VARIANT: "Pilih Varian Produk",
  APPLICATION_NUMBER: "Nombor Permohonan",
  LOAN_RATE: "Jenis Kadar Pinjaman",
  FINANCE_AMOUNT: "Jumlah Pembiayaan",
  APPLICATION_TENURE: "Tempoh (Bulan)*",
  DOCUMENT: "Dokumen",
  SELECT_FILE: "Pilih Fail",
  PASSWORD: "Kata Laluan Jika Ada",
  UPLOAD: "Muat Naik",
  UPLOADED: "Dimuat Naik",
  PREVIEW: "Pralihat",
  REQUIRED_DOCUMENT: "Sila muat naik dokumen yang diperlukan",
  PREVIEW_ALERT: "Sila Muat Naik Dokumen Dahulu",
  UPDATE: "Kemaskini",
  SAVE: "Simpan",
  CONFIRM_SUBMIT_APPLICATION_TITLE: "Sahkan Penyerahan",
  CONFIRM_SUBMIT_APPLICATION_MESSAGE:
    "Adakah anda pasti mahu menyerahkan permohonan anda?",
  SERIAL_NO: "Tidak.",
  FORM: "Borang",
  NOTE_ENTER_NAME_AS_PER_IC: "Nota: Masukkan nama mengikut IC anda",

  // ERROR MESSAGE OF APPLICATION FORM
  TITLE_VALIDATION: "Sila Pilih Tajuk",
  FULL_NAME_VALIDATION: "Sila Masukkan Nama Penuh",
  SEX_VALIDATION: "Sila Pilih Jantina",
  DATE_FUTURE: "Tarikh tidak boleh pada masa hadapan",
  DATE_PAST_VALIDATION: "Tarikh tidak boleh lebih awal daripada 1900-01-01",
  DATE_VALIDATION: "Tarikh mesti sah",
  AGE_VALIDATION: "Umur diperlukan",
  AGE_TYPE_VALIDATION: "Umur mesti nombor",
  AGE_POSITIVE_VALIDATION: "Umur mesti positif",
  AGE_INTEGER_VALIDATION: "Umur mesti integer",
  NATIONAL_ID_VALIDATION: "No. IC baharu diperlukan",
  RELIGION_VALIDATION: "Agama diperlukan",
  CHILD_VALIDATION: "Bilangan Anak diperlukan",
  MOTHER_FULL_NAME_VALIDATION: "Nama Ibu diperlukan",
  MEMBERSHIP_VALIDATION: "Nombor Keahlian diperlukan jika ada",
  MEMBER_VALIDATION: "Pilih Ahli untuk memohon kewangan",
  JOB_TYPE_VALIDATION: "Jenis Pekerjaan diperlukan",
  JOB_UNIQUE_CODE_VALIDATION: "Masukkan Jawatan",
  RACE_VALIDATION: "Bangsa diperlukan",
  MARITAL_STATUS_VALIDATION: "Status perkahwinan diperlukan",
  DEPENDENCY_VALIDATION: "Tanggungan diperlukan",
  MOBILE_PHONE_REQUIRED: "Telefon bimbit diperlukan",
  MOBILE_PHONE_DIGIT_VALIDATION: "Nombor telefon mesti mengandungi hanya digit",
  HOME_PHONE_DIGIT_VALIDATION: "Nombor telefon mesti mengandungi hanya digit",
  PERSONAL_EMAIL_REQUIRED: "Emel diperlukan",
  EMAIL_FORMAT_VALIDATION: "Format emel tidak sah",
  WORK_EMAIL_FORMAT_VALIDATION: "Format emel kerja tidak sah",
  HIGHEST_QUALIFICATION_VALIDATION: "Kelayakan tertinggi diperlukan",
  RELATIONSHIP_VALIDATION: "Hubungan diperlukan",

  HOUSE_ADDRESS_VALIDATION: "Sila masukkan alamat rumah",
  POSTCODE_VALIDATION: "Poskod diperlukan",
  POSTCODE_INVALID: "Poskod Mesti 6 Digit",
  CITY_VALIDATION: "Nama bandar diperlukan",
  STATE_VALIDATION: "Negeri diperlukan",
  MAILING_ADDRESS_VALIDATION: "Alamat surat-menyurat diperlukan",
  MAILING_POSTCODE_VALIDATION: "Poskod surat-menyurat diperlukan",
  MAILING_CITY_VALIDATION: "Nama bandar surat-menyurat diperlukan",
  MAILING_STATE_VALIDATION: "Negeri surat-menyurat diperlukan",
  RESIDENTIAL_LEVEL_VALIDATION: "Sila pilih jenis Pemilikan Kediaman",
  OTHER_VALUE_VALIDATION: "Sila Masukkan Peringkat Kediaman",

  EMPLOYER_NAME_VALIDATION: "Sila masukkan Nama Majikan",
  JOB_VALIDATION: "Tambah masukkan kerja",
  PHONE_NUMBER_VALIDATION: "Masukkan Nombor Telefon",
  PHONE_NUMBER_INVALID: "Sila berikan nombor telefon yang sah",
  DEPARTMENT_VALIDATION: "Jabatan diperlukan",
  RETIREMENT_AGE_VALIDATION: "Umur Persaraan diperlukan",
  RETIREMENT_AGE_INVALID: "Hanya nombor yang dibenarkan",
  START_WORK_DATE_VALIDATION: "Tarikh Mula Bekerja diperlukan",
  END_WORK_DATE_VALIDATION: "Tarikh Tamat Bekerja diperlukan",
  END_DATE_NOT_SMALL_THAN_START_DATE:
    "Tarikh tamat mesti tidak sebelum tarikh mula",
  DATE_INVALID: "Sila Masukkan Tarikh Yang Sah",
  PERIOD_OF_SERVICE_VALIDATION: "Tempoh Perkhidmatan diperlukan",
  PERIOD_OF_SERVICE_INVALID: "Tempoh perkhidmatan tidak sah",
  OPTION_VALIDATION: "Sila pilih status pekerjaan",
  OFFICE_ADDRESS_VALIDATION: "Sila masukkan alamat pejabat",

  BASIC_SALARY_VALIDATION: "Masukkan Gaji Asas Anda",
  BASIC_SALARY_INVALID: "Input mesti nombor",
  SALARY_INCREMENT_VALIDATION: "Masukkan Jumlah Kenaikan Gaji Anda",
  SALARY_INCREMENT_INVALID: "Input mesti nombor",
  FIXED_ALLOWANCE_VALIDATION: "Masukkan Elaun Tetap Anda",
  FIXED_ALLOWANCE_INVALID: "Input mesti nombor",
  DATE_OF_INCREMENT_VALIDATION: "Sila pilih tarikh",
  DATE_OF_INCREMENT_INVALID: "Format tarikh tidak sah",
  TOTAL_DEDUCTION_VALIDATION: "Potongan Jumlah diperlukan",
  TOTAL_DEDUCTION_INVALID: "Input Mesti Nombor",
  NET_SALARY_VALIDATION: "Medan gaji bersih tidak boleh kosong",
  NET_SALARY_INVALID: "Input mesti nombor",
  START_DATE_REQUIRED: "Sila pilih mula dahulu",

  NAME_OF_BANK_VALIDATION: "Nama Bank diperlukan",
  BANKS_BRANCH_VALIDATION: "Cawangan Bank diperlukan",
  ACCOUNT_NUMBER_VALIDATION: "Nombor Akaun diperlukan",
  ACCOUNT_NUMBER_POSITIVE: "Nombor Akaun mesti positif",
  ACCOUNT_NUMBER_INVALID: "Sila masukkan nombor akaun yang sah.",
  TYPE_OF_ACCOUNT_VALIDATION: "Sila Pilih Jenis Akaun",

  CATEGORY_VALIDATION: "Sila Pilih Kategori",
  PRODUCT_VALIDATION: "Sila Pilih Produk",
  SELECT_VARIANT: "Sila Pilih Varian Produk",
  APPLICATION_NUMBER_VALIDATION: "Masukkan Nombor Permohonan Anda",
  FINANCE_AMOUNT_VALIDATION: "Jumlah Pembiayaan diperlukan",
  FINANCE_AMOUNT_POSITIVE: "Jumlah Pembiayaan mesti positif",
  FINANCE_AMOUNT_INVALID: "Sila masukkan jumlah pembiayaan yang sah.",
  TENURE_OF_APPLICATION_VALIDATION: "Masukkan Tempoh Permohonan",
  NUMBER_VALIDATION: "Sila masukkan nombor permohonan yang sah",
  LOAN_RATE_TYPE_REQUIRED: "Pilih Jenis Kadar Pinjaman",
  INTEREST_RATE_POSITIVE: "Kadar Faedah hendaklah lebih besar daripada sifar",
  TENURE_OF_APPLICATION_POSITIVE: "Tempoh permohonan mesti positif",
  TENURE_OF_APPLICATION_INVALID: "Sila masukkan tempoh permohonan yang sah",
  INTEREST_RATE_INVALID: "Sila masukkan kadar faedah yang sah",

  FINANCE_NAME_VALIDATION: "Nama Institusi Kewangan diperlukan",
  LOAN_AMOUNT_VALIDATION: "Jumlah Pinjaman Diperlukan",
  MONTHLY_COMMIT_VALIDATION: "Potongan Bulanan Diperlukan",
  LOAN_TENURE_VALIDATION: "Tempoh Pinjaman Diperlukan",
  SETTLEMENT_VALIDATION:
    "Jika anda mempunyai surat penyelesaian, pilih ya. Jika tidak, pilih tidak",
  ALL_NUMBER_VALIDATION: "Sila masukkan hanya Nombor",
  VALIDITY_PERIOD_REQUIRED:
    "Tempoh sah diperlukan apabila anda mempunyai surat penyelesaian",

  DELETE_EXISTING_FINANCE_TITLE: "Sahkan",
  DELETE_EXISTING_FINANCE_DESCRIPTION: "Adakah anda pasti untuk memadam?",
  DELETE: "Padam",

  PRIMARY: "Rendah",
  SECONDARY: "Menengah",
  DIPLOMA: "Diploma",
  DEGREE: "Ijazah Sarjana Muda",
  MASTERS: "Ijazah Sarjana",
  DOCTORATE_DEGREE: "Ijazah Kedoktoran",
  PROFESSOR: "Kelulusan Prof",
  SUPPORT_FILE: "Fail yang disokong",
  REMOVE: "Alih keluar",
  LOAN_TYPE: "jenis pinjaman",
  UNSECURED_LOAN: "Pembiayaan Tidak Bercagar",
  FINANCE_PRODUCT: "Produk Kewangan",
  HAS_CONSUMER_PRODUCT_ALLOW: "Adakah anda ingin menambah produk pengguna?",
  FINANCE_PRODUCT_REQUIRED: "Pilih Produk Kewangan",
  FINANCE_AMOUNT_CANNOT_BE_LESS_THAN_MIN:
    "Jumlah kewangan tidak boleh kurang daripada {{min}}",
  FINANCE_AMOUNT_CANNOT_BE_GREATER_THAN_MAX:
    "Jumlah kewangan tidak boleh melebihi {{max}}",
  MARITAL_STATUS: "Status Perkahwinan",
  MARITAL_STATUS_IS_REQUIRED: "Status perkahwinan diperlukan",

  // standart validation messages
  TEXT_FIELD_REQUIRED_VALIDATION: "{{fieldName}} diperlukan",
  SELECT_FIELD_REQUIRED: "Sila pilih pilihan yang sah",
  MINIMUM_VALIDATION:
    "{{fieldName}} mestilah lebih besar daripada atau sama dengan  {{min}}",
  MAXIMUM_VALIDATION:
    "{{fieldName}} mestilah kurang daripada atau sama dengan {{max}}",
  VALID_DATE_VALIDATION: "Sila masukkan tarikh yang sah",
  NUMBER_INVALID_VALIDATION: "Sila masukkan nombor yang sah",

  MAILING_CITY: "Bandar Surat",
  MAILING_POST_CODE: "Poskod Pos",
  MAILING_STATE: "Negeri Mel",
  ACTIVE_FINANCE_TITLE: "Aplikasi Kewangan Aktif",
  ACTIVE_FINANCE_BUTTON: "Pergi Ke Halaman Butiran",

  // MODIFY APPLICATION INFO
  MODIFY_APPLICATION_TITLE: "Ubah Permohonan",
  MODIFY_APPLICATION_DIS:
    "Klik 'Halaman Butiran' untuk membuat sebarang perubahan pada permohonan anda.",
  MODIFY_APPLICATION_BUTTON: "Halaman Butiran",

  AGENT_CODE: "Kod Agensi/Pengurus",
  REFERRAL_CODE_LENGTH: "Kod Agensi mestilah tepat 6 digit",
  NUMBER_VALIDATION_FOR_AGENT_CODE: "Hanya nombor yang diterima",
  ADD_MEMBER: "Tambah Ahli",
  OTHER_DOCUMENT: "Lain-lain"
};

export default locale;
