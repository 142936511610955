import { Box, Dialog, Grid, Typography } from "@mui/material";
import { formatAmount, formatDate, removeLocalStorageItem, setLocalStorageItem } from "src/app/services/utils";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import CustomCheckbox from "../Form/CustomCheckbox";
import history from "@history";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";
import { getActiveFinanceDetail } from "app/store/application-form/applicationFormSlice";
import { useSelector } from "react-redux";

i18next.addResourceBundle("en", "dialog", en);
i18next.addResourceBundle("ms", "dialog", ms);

const PendingKYCDialog = ({ open, onClose }) => {
  const { t } = useTranslation("dialog");
  const activeFinanceDetail = useSelector(getActiveFinanceDetail);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleProcessKYC = () => {
    history.push(`/user/my-application-kyc/${activeFinanceDetail?.id}`);
    onClose();
  };

  const handleCheckboxChange = (event) => {
    if (event) {
      const today = new Date().toLocaleDateString();
      setLocalStorageItem("pendingKYCDialogDate", today);
    } else {
      removeLocalStorageItem("pendingKYCDialogDate");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <div className="flex flex-col p-16">
        <div className="flex flex-col items-center">
          <img
            src="assets/images/KYC/pendingKYC.svg"
            alt="Pending KYC"
            className="max-w-160 max-h-160"
          />
        </div>
        <Box className="flex flex-col gap-10 mx-20">
          <Typography variant="h6" className="text-center">
            {t("PENDING_KYC")}
          </Typography>
          <Typography variant="body1" className="text-justify mb-5">
            {t("PENDING_KYC_DESC")}&nbsp;
            <strong>{activeFinanceDetail.applicationNumber}</strong>.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {activeFinanceDetail.financeProductName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1">
                <strong>{t("AMOUNT")}: </strong>
                {formatAmount(activeFinanceDetail.financeAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1">
                <strong>{t("CREATED")}: </strong>
                {formatDate(
                  activeFinanceDetail.created,
                  "DD/MMM/YYYY",
                  "DD/MMM/YYYY HH:mm:ss"
                )}
              </Typography>
            </Grid>
          </Grid>
          <div className="flex justify-start items-center">
            <CustomCheckbox
              label={t("DONT_SHOW_AGAIN")}
              onChange={handleCheckboxChange}
            />
          </div>
        </Box>
        <div className="flex justify-end items-center gap-10">
          <ColoredSubmitButton
            variant="outlined"
            onClick={onClose}
            text={t("LATER")}
          />
          <ColoredSubmitButton
            variant="contained"
            onClick={handleProcessKYC}
            text={t("KYC_PROCESS")}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PendingKYCDialog;
