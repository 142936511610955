import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getPaginationData,
  getMemberList,
  getMemberListData,
  setPagination,
  getMemberListLoading,
} from "app/store/user/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTableCustom from "app/shared-components/ReactTable/ReactTableCustom";
import { Box, Container, Paper } from "@mui/material";
import {
  StyledButton,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { Link } from "react-router-dom";
import history from "@history";
import TableMenu from "./TableMenu";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import StatusChip from "app/shared-components/statusChip/StatusChip";
import EmailLink from "app/shared-components/Link/EmailLink";
import {
  handleQueryParamsAndFilters,
  useQueryParams,
} from "src/app/services/queryParamsUtils";
import AgentDetailTooltip from "app/shared-components/Tooltip/AgentDetailTooltip";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import themesConfig from "app/configs/themesConfig";

const MemberListTable = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const memberList = useSelector(getMemberListData);
  const pagination = useSelector(getPaginationData);
  const loading = useSelector(getMemberListLoading);
  const { getQueryParams, updateQueryParams } = useQueryParams();

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortField, sortOrder }) => {
      handleQueryParamsAndFilters({
        dispatch,
        updateQueryParams,
        pageSize,
        pageIndex,
        sortField,
        sortOrder,
        setPaginationAction: setPagination,
      });
      dispatch(getMemberList({ pageIndex }));
    },
    []
  );

  const redirectToDetailPage = (id) => {
    history.push(`/user/member/${id}`);
  };

  const columns = React.useMemo<any[]>(
    () => [
      {
        accessorKey: "fullName",
        header: () => t("NAME"),
        cell: (value) => (
          <a
            className="cursor-pointer"
            onClick={() => redirectToDetailPage(value.row.original.id)}
          >
            {`${value.row.original?.title} ${value.getValue()}`}
          </a>
        ),
      },
      {
        accessorKey: "email",
        header: () => t("EMAIL"),
        cell: (value) => <EmailLink email={value.getValue()} />,
      },
      {
        accessorKey: "phoneNumber",
        header: () => t("MOBILE_NUMBER"),
        cell: (value) => <PhoneNumberLink phoneNumber={value.getValue()} />,
      },
      {
        accessorKey: "nationalId",
        header: () => t("NATIONAL_ID"),
        cell: (value) =>
          value.row.original?.userAdditionalInformation?.nationalId || "--",
      },
      {
        header: () => t("AGENT_CODE"),
        accessorKey: "agentUser",
        cell: (value) => {
          return (
            <div className="text-center">
              {value.getValue() ? (
                <AgentDetailTooltip agentDetail={value.getValue()} />
              ) : (
                "--"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "created",
        header: () => t("CREATED_AT"),
      },
      {
        accessorKey: "status",
        header: () => t("STATUS"),
        cell: (value) => (
          <StatusChip
            status={value.getValue()}
            label={value.getValue()}
          ></StatusChip>
        ),
      },
      {
        accessorKey: "actions",
        header: () => <div className="text-right"> {t("ACTIONS")} </div>,
        cell: (value) => (
          <div className="flex justify-end">
            <TableMenu data={value.row.original} history={history} />
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Can
      permission={FeaturePermissions.User.List}
      errorMessage="ACCESS_DENIED_VIEW_MEMBER"
    >
      <Box
        sx={{
          backgroundColor: themesConfig.default.palette.background.default,
        }}
        className="h-full"
      >
        <Container
          maxWidth="lg"
          className="pl-10 md:pl-76 pr-10 md:pr-76 mb-64 mt-10"
        >
          <CommonBreadcrumbs currentPageName={t("MEMBER")} />
          <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full">
            <Box
              className="mt-10 mb-20"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <StyledTypography variant="h5" fontWeight={"bold"}>
                {t("MEMBER")}
              </StyledTypography>
              <Can permission={FeaturePermissions.User.Create}>
                <Link to="/user/member/add-member">
                  <StyledButton>{t("ADD_MEMBER")} </StyledButton>
                </Link>
              </Can>
            </Box>

            <ReactTableCustom
              fetchData={fetchData}
              columns={columns}
              data={memberList}
              isLoading={loading}
              pageCount={pagination?.totalCount}
              paginationState={getQueryParams()}
            />
          </Paper>
        </Container>
      </Box>
    </Can>
  );
};

export default MemberListTable;
