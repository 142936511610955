import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsEditAddressDialogOpen,
  getSlug,
  getUserAddress,
  getUserAddressData,
  getUserId,
  setIsEditAddressDialogOpen,
} from "app/store/user/userSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import AddressForm from "./form/AddressForm";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import CustomIconButton from "app/shared-components/Button/CustomIconButton";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import ChangeDetectDialog from "./form/ChangeDetectDialog";
import Can from "src/app/services/FeaturePermission/Can";
import { hasPermission } from "src/app/services/utils";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { loginUserRoles } from "src/app/common/constant";
import themesConfig from "app/configs/themesConfig";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

export default function AddressInfo({
  memberId = null,
  styleContainer = true,
  ...props
}) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const userId = useSelector(getUserId);
  const slug = useSelector(getSlug);
  const isDialogOpen = useSelector(getIsEditAddressDialogOpen);
  const userAddress = useSelector(getUserAddressData);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  if (!hasPermission(FeaturePermissions.UserAddress.List)) {
    return <ErrorMessage errorMessage="NOT_ALLOWED_VIEW_ADDRESS_DETAIL" />;
  }

  useEffect(() => {
    if (memberId) {
      dispatch(getUserAddress(memberId));
    } else {
      dispatch(getUserAddress(userId));
    }
  }, [userId, memberId, dispatch]);

  const handleOpen = () => {
    dispatch(setIsEditAddressDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setIsEditAddressDialogOpen(false));
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  return (
    <Box
      sx={{
        backgroundColor: themesConfig.default.palette.background.default,
      }}
      className="h-full"
    >
      <Container
        maxWidth="lg"
        className={
          styleContainer ? "pl-10 mt-10 md:pl-76 pr-10 md:pr-76 mb-64" : ""
        }
      >
        {slug === loginUserRoles.Customer && (
          <CommonBreadcrumbs currentPageName={t("ADDRESS_INFO")} />
        )}
        <Paper className={`flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full ${props.className}`}>
          <Box className="flex  mb-10 items-center gap-12">
            <StyledTypography variant="h5" fontWeight={"bold"} className="">
              {t("ADDRESS_INFO")}
            </StyledTypography>
            <Can permission={FeaturePermissions.User.UpdateAddress}>
              <CustomIconButton onClick={handleOpen} />
            </Can>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("HOUSE_ADDRESS")}
                value={userAddress?.houseAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("POST_CODE")}
                value={userAddress?.housePostalCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem label={t("CITY")} value={userAddress?.houseCity} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem label={t("STATE")} value={userAddress?.houseState} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("RESIDENTIAL_LEVEL")}
                value={userAddress?.houseStatus}
              />
            </Grid>
          </Grid>

          <Box className="mt-40 mb-10">
            <StyledTypography variant="body2" fontWeight={"bold"}>
              {t("MAILING_ADDRESS")}
            </StyledTypography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("MAILING_ADDRESS")}
                value={userAddress?.mailingAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("POST_CODE")}
                value={userAddress?.mailingPostalCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem label={t("CITY")} value={userAddress?.mailingCity} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DetailItem
                label={t("STATE")}
                value={userAddress?.mailingState}
              />
            </Grid>
          </Grid>
        </Paper>
        <CustomDialog
          open={isDialogOpen || false}
          onClose={handleDialogClose}
          content={{
            title: t("EDIT_ADDRESS"),
            component: <AddressForm {...{ userAddress, setIsDirty }} />, // AddressForm component is passed as content
          }}
        />
        <ChangeDetectDialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          onButtonClick={() => setConfirmOpen(false)}
        />
      </Container>
    </Box>
  );
}
