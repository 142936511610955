import { Grid } from "@mui/material";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import PreviewMedia from "app/shared-components/Dialog/PreviewMedia";
import FilePreview from "app/shared-components/preview-file/FilePreview";
import { StyledTypographyGrey } from "app/shared-components/Styles/CustomStyle";
import { useTranslation } from "react-i18next";

const DigitalSignatureDetailAccordion = ({ signatureDetail }) => {
    const { t } = useTranslation("user");

    const SignatureContent = (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <DetailItem
                        label={t("CUSTOMER_SINGED")}
                        value={signatureDetail?.isCustomerSinged ? "Yes" : "No"}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <DetailItem
                        label={t("ADMIN_SINGED")}
                        value={signatureDetail?.isAdminSinged ? "Yes" : "No"}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <DetailItem
                        label={t("LAWYER_SINGED")}
                        value={signatureDetail?.isLawyerSinged ? "Yes" : "No"}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <StyledTypographyGrey variant="caption">
                        {t("FORM_J_PDF")}
                    </StyledTypographyGrey>
                    <PreviewMedia filePath={signatureDetail?.formJPdfPath}>
                        <FilePreview fileUrl={signatureDetail?.formJPdfPath} />
                    </PreviewMedia>
                </Grid>
            </Grid>
        </>
    )

    return (
        <CommonAccordion title={t("DOCUMENT_SIGNED")} content={SignatureContent} editIcon={false} />
    );
};

export default DigitalSignatureDetailAccordion;
