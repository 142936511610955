import FusePageCarded from "@fuse/core/FusePageCarded";
import DetailSidebarContent from "../Card/DetailSidebarContent";
import withReducer from "app/store/withReducer";
import documentCardSlice, {
  getDocumentDetail,
  getDocumentList,
  getIsDocumentDialogOpen,
  setIsDocumentDialogOpen,
  setUpdateDocumentDetail,
} from "./store/documentCardSlice";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { StyledTypography } from "../Styles/CustomStyle";
import UploadDocumentDialog from "./UploadDocumentDialog";
import UploadDocuments from "./UploadDocuments";
import { useTranslation } from "react-i18next";

i18next.addResourceBundle("en", "documentCard", en);
i18next.addResourceBundle("ms", "documentCard", ms);

const DocumentPage = (props) => {
  const {
    userId = null,
    financeApplicationId = null,
    pageToRedirect = "",
    documentType = null,
    showFinishButton = false,
    remarksList = null,
    rounded = false
  } = props;
  const { t } = useTranslation("user");
  const documentList = useSelector(getDocumentList);
  const dispatch: any = useDispatch();

  const isDocumentDialogOpen = useSelector(getIsDocumentDialogOpen);
  const selectedDocument = useSelector(getDocumentDetail);
  const selectedItem = find(documentList, { id: selectedDocument?.id });

  const handleEdit = async ({ documentDetails }) => {
    dispatch(setUpdateDocumentDetail(documentDetails));
    dispatch(setIsDocumentDialogOpen(true));
  };

  const handleClose = () => {
    dispatch(setUpdateDocumentDetail(null));
    dispatch(setIsDocumentDialogOpen(false));
  };

  return (
    <div>
      <FusePageCarded
        content={
          <UploadDocuments
            pageToRedirect={pageToRedirect}
            showFinishButton={showFinishButton}
            financeApplicationId={financeApplicationId}
            remarksList={remarksList}
            type={documentType}
            userId={userId}
          />
        }
        rounded={rounded}
        rightSidebarOpen={Boolean(selectedItem)}
        rightSidebarContent={
          <div className="w-full">
            <DetailSidebarContent
              documentList={documentList}
              onEdit={handleEdit}
            />
          </div>
        }
        rightSidebarWidth={400}
        //   scroll={isMobile ? "normal" : "content"}
      />
      {isDocumentDialogOpen && (
        <UploadDocumentDialog
          open={isDocumentDialogOpen}
          handleClose={handleClose}
          documentList={documentList}
          financeApplicationId={financeApplicationId}
          userId={userId}
        />
      )}
    </div>
  );
};

export default withReducer(
  "documentCard",
  documentCardSlice.reducer
)(DocumentPage);
