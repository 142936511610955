import React, { useState } from "react";
import { Container, Grid, Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsEditPersonalDetailDialogOpen,
  setIsEditPersonalDialogOpen,
} from "app/store/user/userSlice";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import UpdatePersonalInfoForm from "./form/UpdatePersonalInfoForm";
import CustomIconButton from "app/shared-components/Button/CustomIconButton";
import ChangeDetectDialog from "./form/ChangeDetectDialog";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import AgentDetailTooltip from "app/shared-components/Tooltip/AgentDetailTooltip";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

export default function PersonalInfo({
  styleContainer = true,
  isEdit = true,
  agencyCodeShow = true,
  personalInfo = null,
  ...props
}) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isEditPersonalDetailDialogOpen = useSelector(
    getIsEditPersonalDetailDialogOpen
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleOpen = () => {
    dispatch(setIsEditPersonalDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setIsEditPersonalDialogOpen(false));
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const nameWithTitle = personalInfo?.title + personalInfo?.fullName;
  return (
    <Container
      maxWidth="lg"
      className={styleContainer ? "pl-10 mt-10 md:pl-76 pr-10 md:pr-76" : ""}
    >
      <Paper className={`flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full ${props.className}`}>
        <div className="flex  mb-10 gap-12 items-center">
          <div>
            <StyledTypography variant="h5" fontWeight={"bold"}>
              {t("PERSONAL_INFO")}
            </StyledTypography>
          </div>
          {isEdit && (
            <Can permission={FeaturePermissions.User.Update}>
              <CustomIconButton onClick={handleOpen} />
            </Can>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <DetailItem label={t("NAME")} value={nameWithTitle} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DetailItem
              label={t("EMAIL")}
              value={<EmailLink email={personalInfo?.email} />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <DetailItem
              label={t("DEFAULT_LANGUAGE")}
              value={personalInfo?.defaultLanguage}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <DetailItem
              label={t("MOBILE_NUMBER")}
              value={
                <PhoneNumberLink
                  phoneNumber={personalInfo?.phoneNumber}
                ></PhoneNumberLink>
              }
            />
          </Grid>
          {agencyCodeShow && (
            <Grid item xs={6}>
              <DetailItem
                label={
                  props.showUserUniqueCode ? t("MANAGER_CODE") : t("AGENT_CODE")
                }
                value={
                  props.showUserUniqueCode ? (
                    personalInfo?.userUniqueCode
                  ) : !!personalInfo?.agentUser ? (
                    <AgentDetailTooltip agentDetail={personalInfo?.agentUser} />
                  ) : (
                    "--"
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Paper>

      <CustomDialog
        open={isEditPersonalDetailDialogOpen || false}
        onClose={handleDialogClose}
        content={{
          title: t("EDIT_PERSONAL_INFO"),
          component: (
            <UpdatePersonalInfoForm {...{ personalInfo, setIsDirty }} />
          ),
        }}
      />
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </Container>
  );
}
