import React, { useState } from "react";
import AdditionalInfoForm from "./form/AdditionalInfoForm";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { Box, Container, Grid, Paper } from "@mui/material";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useTranslation } from "react-i18next";
import {
  getIsEditAdditionalInfoDialogOpen,
  setIsEditAdditionalInfoDialogOpen,
  getUserId,
} from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "app/shared-components/Button/CustomIconButton";
import ChangeDetectDialog from "./form/ChangeDetectDialog";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";

const UserAdditionalInfo = ({
  additionalData = null,
  styleContainer = true,
  ...props
}) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const isEditAdditionalInfoDialogOpen = useSelector(
    getIsEditAdditionalInfoDialogOpen
  );

  const handleEditAdditionalInfoOpen = () => {
    dispatch(setIsEditAdditionalInfoDialogOpen(true));
  };
  const handleClose = () => {
    dispatch(setIsEditAdditionalInfoDialogOpen(false));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  return (
    <>
      <Container
        maxWidth="lg"
        className={
          styleContainer ? "pl-10 mt-40 md:pl-76 pr-10 md:pr-76 mb-64" : ""
        }
      >
        <Paper className={`flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full ${props.className}`}>
          <div className="flex mb-10 gap-12 items-center">
            <div>
              <StyledTypography variant="h5" fontWeight={"bold"}>
                {t("ADDITIONAL_INFORMATION")}
              </StyledTypography>
            </div>
            <Can
              permission={FeaturePermissions.User.UpdateAdditionalInformation}
            >
              <CustomIconButton onClick={handleEditAdditionalInfoOpen} />
            </Can>
          </div>
          <Grid container spacing={2}>
            {/* First Row */}
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem label={t("SEX")} value={additionalData?.sex} />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("DATE_OF_BIRTH")}
                value={additionalData?.dateOfBirth}
              />
            </Grid>

            {/* Second Row */}
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("JOB_TYPE")}
                value={additionalData?.jobType}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("JOB_UNIQUE_CODE")}
                value={additionalData?.jobUniqueCode}
              />
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("MOTHER_FULL_NAME")}
                value={additionalData?.motherFullName}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("NATIONAL_ID")}
                value={additionalData?.nationalId}
              />
            </Grid>

            {/* Third Row */}
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("RELIGION")}
                value={additionalData?.religion}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem label={t("RACE")} value={additionalData?.race} />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("HIGHEST_QUALIFICATION")}
                value={additionalData?.highestQualification}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <DetailItem
                label={t("MARITAL_STATUS")}
                value={additionalData?.maritalStatus}
              />
            </Grid>
          </Grid>

          <Box className="mt-40 mb-10">
            <StyledTypography
              variant="body2"
              fontWeight={"bold"}
              className="mb-10"
            >
              {t("CONTACT")}
            </StyledTypography>
            <Grid container spacing={2}>
              {/* First Row */}
              <Grid item xs={6} sm={4} md={6}>
                <DetailItem
                  label={t("WORK_EMAIL")}
                  value={
                    <EmailLink
                      email={additionalData?.workEmailAddress}
                    ></EmailLink>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4} md={6}>
                <DetailItem
                  label={t("HOME_PHONE_NUMBER")}
                  value={
                    <PhoneNumberLink
                      phoneNumber={additionalData?.hpNumber}
                    ></PhoneNumberLink>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4} md={6}>
                <DetailItem
                  label={t("PERSONAL_EMAIL")}
                  value={
                    <EmailLink
                      email={additionalData?.personalEmailAddress}
                    ></EmailLink>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <CustomDialog
          open={isEditAdditionalInfoDialogOpen || false}
          onClose={handleDialogClose}
          content={{
            title: t("EDIT_ADDITIONAL_INFO"),
            component: (
              <AdditionalInfoForm
                {...{ additionalData, setIsDirty, isEdit: true }}
              />
            ),
          }}
        />
        <ChangeDetectDialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          onButtonClick={() => setConfirmOpen(false)}
        />
      </Container>
    </>
  );
};

export default UserAdditionalInfo;
