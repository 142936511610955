import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import { isEmpty } from "lodash";
import api from "src/app/services/apiService";
import { buildQueryParams } from "src/app/services/utils";

interface messengerState {
  chatContactList: any[];
  contactList: any[]; // For User List For the Agent AND Mini Agent
  chatContactsListPagination: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };
  contactsListPagination: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };
  selectedConversationUserDetail: any;
  chatList: any[];
  chatListPagination: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };
  loading: {
    isChatContactsListLoading: boolean;
    isChatListLoading: boolean;
    isSendMessageLoading: boolean;
    isStartConversionLoading: boolean;
    isContactListLoading: boolean;
  };
}

const initialState: messengerState = {
  chatContactList: [],
  contactList: [],
  chatContactsListPagination: {
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  contactsListPagination: {
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  selectedConversationUserDetail: null,
  chatList: [],
  chatListPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalCount: 0,
  },
  loading: {
    isChatContactsListLoading: false,
    isChatListLoading: false,
    isSendMessageLoading: false,
    isStartConversionLoading: false,
    isContactListLoading: false,
  },
};

export const getChatContactList =
  (searchTerm = "") =>
  async (dispatch, getState) => {
    const pagination = getChatContactsListPaginationData(getState());
    try {
      dispatch(setChatContactsListLoading(true));
      const existingChatContactList = getChatContactsListData(getState());

      const queryParams = buildQueryParams({
        PageSize: pagination.pageSize,
        PageIndex: pagination.pageIndex,
        Search: searchTerm,
      });

      const response: any = await api.get(`/v1/Conversation?${queryParams}`);
      if (response && response.result) {
        dispatch(
          setChatContactsListPagination({
            pageIndex: response.result.conversations.page,
            pageSize: response.result.conversations.pageSize,
            totalCount: response.result.conversations.totalCount,
          })
        );
        const newChatContactList = response?.result?.conversations?.items || [];
        const updatedChatContactList: any = [
          ...existingChatContactList,
          ...(existingChatContactList.length > 0
            ? newChatContactList.filter(
                (newContact) =>
                  !existingChatContactList.some(
                    (existingContact) => existingContact.id === newContact.id
                  )
              )
            : newChatContactList),
        ];
        dispatch(setChatContactList(updatedChatContactList));
      }
      dispatch(setChatContactsListLoading(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setChatContactsListLoading(false));
      console.error(error);
    }
  };

export const getChatContactById =
  ({ conversationId }) =>
  async (dispatch, getState) => {
    try {
      const existingChatContactList = getChatContactsListData(getState());

      const response: any = await api.get(`/v1/Conversation/${conversationId}`);
      if (response && response.result) {
        const newChatContact = response.result;

        const isAlreadyExists = existingChatContactList?.some(
          (contact) => contact.id === newChatContact.id
        );

        if (!isAlreadyExists) {
          const updatedChatContactList: any = [
            newChatContact,
            ...existingChatContactList,
          ];
          dispatch(setChatContactList(updatedChatContactList));
        }
        return newChatContact;
      }
      return false;
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
      return false;
    }
  };

export const getChatList =
  ({ conversationId }) =>
  async (dispatch, getState) => {
    const pagination = getChatListPaginationData(getState());

    try {
      dispatch(setChatListLoading(true));

      const queryParams = buildQueryParams({
        PageSize: pagination.pageSize,
        PageIndex: pagination.pageIndex,
        ConversationId: conversationId,
      });

      const response: any = await api.get(`/v1/ChatMessage?${queryParams}`);

      if (
        response &&
        response.result &&
        response.result.messages &&
        response.result.messages.items
      ) {
        dispatch(setChatList(response.result.messages.items));
        dispatch(
          setChatListPagination({
            pageIndex: response.result.messages.page,
            totalCount: response.result.messages.totalCount,
            pageSize: response.result.messages.pageSize,
          })
        );
        return true;
      } else {
        dispatch(setChatList([]));
        return false;
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setChatListLoading(false));
      console.error(error);
      dispatch(setChatList([]));
      return false;
    } finally {
      dispatch(setChatListLoading(false));
    }
  };

export const getContactList =
  ({ roleId, searchTerm }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsContactListLoading(true));
      const pagination = getContactsListPaginationData(getState());
      const roleIdQuery = Array.isArray(roleId) ? roleId.join(",") : roleId;

      const queryParams = buildQueryParams({
        PageSize: pagination.pageSize,
        PageIndex: pagination.pageIndex,
        Search: searchTerm,
        RoleIds: roleIdQuery,
      });

      const response: any = await api.get(`/v1/User?${queryParams}`);
      if (response && response.result) {
        dispatch(setContactList(response.result.items));
        dispatch(
          setContactsListPagination({
            pageIndex: response.result.page - 1,
            totalCount: response.result.totalCount,
            pageSize: response.result.pageSize,
          })
        );
        return response.result.items;
      } else {
        dispatch(setContactList([]));
        return [];
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
      dispatch(setContactList([]));
      return [];
    } finally {
      dispatch(setIsContactListLoading(false));
    }
  };

export const sendCommunication =
  (request_data) => async (dispatch, getState) => {
    dispatch(setIsSendMessageLoading(true));
    try {
      const response: any = await api.post("/v1/ChatMessage", request_data);
      if (response && response.result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
      return false;
    } finally {
      dispatch(setIsSendMessageLoading(false));
    }
  };

export const startConversion = (request_data) => async (dispatch, getState) => {
  dispatch(setIsStartConversionLoading(true));
  try {
    const response: any = await api.post("/v1/Conversation", request_data);
    if (response && response.result) {
      dispatch(setSelectedConversationUserDetail(response.result));
      dispatch(getChatContactList());
      return response.result;
    } else {
      return false;
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    console.error(error);
    return false;
  } finally {
    dispatch(setIsStartConversionLoading(false));
  }
};

export const markAsReadChat = (request_data) => async (dispatch, getState) => {
  try {
    const response: any = await api.put(
      "/v1/ChatMessage/MarkAsRead",
      request_data
    );
    if (response && response.result) {
      const updatedMessages = response.result;
      const existingChat: any = getChatListData(getState());
      const chatContacts = getChatContactsListData(getState());

      if (!isEmpty(existingChat) && !isEmpty(updatedMessages)) {
        const updatedChatList = existingChat.map((chat) => {
          const updatedMessage = updatedMessages.find(
            (message) => message.id === chat.id
          );

          return updatedMessage
            ? {
                ...chat,
                readAt: updatedMessage.readAt,
              }
            : chat;
        });

        dispatch(setChatList(updatedChatList));
      }

      if (!isEmpty(chatContacts) && !isEmpty(updatedMessages)) {
        const updatedChatContacts = chatContacts?.map((chat) => {
          const updatedMessage = updatedMessages?.find(
            (message) => message?.id === chat?.lastChatMessage?.id
          );

          return updatedMessage
            ? {
                ...chat,
                lastChatMessage: updatedMessage,
              }
            : chat;
        });

        dispatch(setChatContactList(updatedChatContacts));
      }
      return updatedMessages;
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    console.error(error);
  }
};

// export Get State
export const getChatContactsListPaginationData = (state) =>
  state.messenger.chatContactsListPagination;
export const getChatContactsListData = (state) =>
  state.messenger.chatContactList;
export const getContactsListData = (state) => state.messenger.contactList;
export const getContactsListPaginationData = (state) =>
  state.messenger.contactsListPagination;
export const getSelectedConversationUserDetail = (state) =>
  state.messenger.selectedConversationUserDetail;
export const getChatListData = (state) => state.messenger.chatList;
export const getChatListPaginationData = (state) =>
  state.messenger.chatListPagination;

// Loading selectors start
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};
export const setChatContactsListLoading = (value) =>
  setLoadingState("isChatContactsListLoading", value);

export const setChatListLoading = (value) =>
  setLoadingState("isChatListLoading", value);
export const setIsStartConversionLoading = (value) =>
  setLoadingState("isStartConversionLoading", value);
export const setIsSendMessageLoading = (value) =>
  setLoadingState("isSendMessageLoading", value);
export const setIsContactListLoading = (value) =>
  setLoadingState("isContactListLoading", value);
export const getLoadingStateFactory = (loader) => (state) => {
  return state.messenger.loading[loader] || false;
};
export const getChatContactsListLoading = getLoadingStateFactory(
  "isChatContactsListLoading"
);
export const getIsSendMessageLoading = getLoadingStateFactory(
  "isSendMessageLoading"
);
export const getIsStartConversionLoading = getLoadingStateFactory(
  "isStartConversionLoading"
);
export const getChatListLoading = getLoadingStateFactory("isChatListLoading");
export const getIsContactListLoading = getLoadingStateFactory(
  "isContactListLoading"
);

const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    setChatContactList: (state, action: PayloadAction<[]>) => {
      state.chatContactList = action.payload;
    },
    setChatContactsListPagination: (state, action: PayloadAction<any>) => {
      state.chatContactsListPagination = {
        ...state.chatContactsListPagination,
        ...action.payload,
      };
    },
    setContactList: (state, action: PayloadAction<[]>) => {
      state.contactList = action.payload;
    },
    setContactsListPagination: (state, action: PayloadAction<any>) => {
      state.contactsListPagination = {
        ...state.contactsListPagination,
        ...action.payload,
      };
    },
    setSelectedConversationUserDetail: (state, action: PayloadAction<any>) => {
      state.selectedConversationUserDetail = action.payload;
    },
    setChatList: (state, action: PayloadAction<[]>) => {
      const newChats: any = action.payload;
      const uniqueNewChats =
        newChats &&
        newChats.filter(
          (newChat) =>
            !state.chatList.some(
              (existingChat) => existingChat.id === newChat.id
            )
        );
      newChats.forEach((newChat) => {
        const existingChatIndex = state.chatList.findIndex(
          (chat) => chat.id === newChat.id
        );

        if (existingChatIndex !== -1) {
          state.chatList[existingChatIndex] = {
            ...state.chatList[existingChatIndex],
            ...newChat,
          };
        }
      });
      state.chatList = [...uniqueNewChats, ...state.chatList];
    },
    setChatListPagination: (state, action: PayloadAction<any>) => {
      state.chatListPagination = {
        ...state.chatListPagination,
        ...action.payload,
      };
    },
    addNewChat: (state, action: PayloadAction<any>) => {
      state.chatList.push(action.payload);
    },
    resetChatList: (state) => {
      state.chatList = initialState.chatList;
    },
    resetStore: () => initialState,
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
  },
});

export const {
  setChatContactList,
  resetStore,
  setChatList,
  addNewChat,
  resetChatList,
  setContactList,
  setContactsListPagination,
  setSelectedConversationUserDetail,
  setChatContactsListPagination,
  setChatListPagination,
  setLoading,
} = messengerSlice.actions;
export default messengerSlice.reducer;
