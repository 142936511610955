const locale = {
  DOCUMENTS: "Dokumen",
  EDIT: "Sunting",
  TAB_MEMBER: "Ahli",
  DOCUMENTS_REMARKS: "Catatan Dokumen",
  FINISH: "Selesai",
  PENDING: "Menunggu",
  APPROVED: "Luluskan",
  REJECTED: "Tolak",
  PASSWORD: "Kata laluan",
  UPLOAD_BY: "Dimuat Naik Oleh",
  CREATED_AT: "Dicipta Pada",
  LAST_MODIFIED: "Terakhir Diubah Suai",
  APPROVED_BY: "Disahkan oleh",
  DOWNLOAD: "Muat turun",
  DELETE: "Padam",
  DELETE_DOCUMENT: "Padamkan Dokumen",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DOCUMENT:
    "Adakah anda pasti mahu memadamkan dokumen ini?",
  NO_DOCUMENTS_FOUND: "Tiada Dokumen Ditemui",
  TAB_FINANCE_DOCS: "Dokumen Koperasi"
};

export default locale;
