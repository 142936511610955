import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  finalSubmitApplication,
  getIsVerificationDialogOpen,
  getIsFinanceProcessDialogOpen,
  selectIsButtonLoading,
  sendOTP,
  setFinanceProcessDialogOpen,
  setVerificationDialogOpen,
  getIsOTPGenerateLoading,
  setIsFinalSubmitSignatureDialogOpen,
  getIsFinalSubmitSignatureDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import OtpVerification from "app/shared-components/OtpVerification/OtpVerification";
import { useTranslation } from "react-i18next";
import themesConfig from "app/configs/themesConfig";
import { generateAndVerifyOTPType, videoUrl } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import Box from "@mui/material/Box";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { Button } from "@mui/material";
import { StyledTypographyGrey } from "../Styles/CustomStyle";
import FinalSubmitSignature from "./FinalSubmitSignature";

i18next.addResourceBundle("en", "FinalApplicationSubmit", en);
i18next.addResourceBundle("ms", "FinalApplicationSubmit", ms);

declare global {
  interface Window {
    Cypress?: any;
  }
}

const FinalApplicationSubmit = ({ applicationId, phoneNumber }) => {
  const { t } = useTranslation("FinalApplicationSubmit");
  const dispatch: any = useDispatch();
  const isLoading = useSelector(selectIsButtonLoading);
  const verificationDialogOpen = useSelector(getIsVerificationDialogOpen);
  const isGenerateOTPLoading = useSelector(getIsOTPGenerateLoading);
  const financeProcessDialogOpen = useSelector(getIsFinanceProcessDialogOpen);
  const isSignatureDialogOpen = useSelector(
    getIsFinalSubmitSignatureDialogOpen
  );
  const videoRef = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);
  const [signature, setSignature] = useState(null);
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleCanPlay = () => {
      if (window.Cypress) {
        // For Cypress tests, set a data attribute when video is ready
        video.setAttribute('data-video-ready', 'true');
        
        // Ensure video is loaded before setting time
        video.play().then(() => {
          video.currentTime = video.duration;
          setCurrentTime(video.duration);
          setVideoEnded(true);
          // Set a data attribute to indicate video completion
          video.setAttribute('data-video-completed', 'true');
        }).catch(console.error);
      }
    };

    video.addEventListener('canplay', handleCanPlay);
    
    return () => {
      video.removeEventListener('canplay', handleCanPlay);
    };
  }, [videoRef.current]);

  const handleFinalSubmit = (data) => {
    if (!!data && !!applicationId && !!signature) {
      const request_data = {
        financeApplicationId: applicationId,
        otp: data.otp,
        signature: signature,
      };
      dispatch(finalSubmitApplication(request_data));
    }
  };

  const handleVerificationDialogClose = () => {
    dispatch(setVerificationDialogOpen(false));
  };

  const handleSignatureDialogClose = () => {
    dispatch(setIsFinalSubmitSignatureDialogOpen(false));
  };

  const handleFinanceProcessDialogClose = () => {
    dispatch(setFinanceProcessDialogOpen(false));
  };

  const handleVideoLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  const handleSendOtp = () => {
    dispatch(
      sendOTP({
        mobile: phoneNumber,
        otpType: generateAndVerifyOTPType?.SubmitFinanceApplication,
      })
    );
  };

  const handleFinalSignatureSubmit = (signature) => {
    if (!!signature) {
      setSignature(signature);
      dispatch(setIsFinalSubmitSignatureDialogOpen(false));
      dispatch(setFinanceProcessDialogOpen(true));
    }
  };

  const verificationComponent = (
    <OtpVerification
      color={themesConfig.default.palette.common.black}
      onOtpSubmit={handleFinalSubmit}
      loading={isLoading}
      onResendOtp={handleSendOtp}
      isLogin={false}
      phoneNumber={phoneNumber}
    />
  );

  const signatureComponent = (
    <FinalSubmitSignature onSubmit={handleFinalSignatureSubmit} />
  );

  const remainingTime = videoDuration - currentTime;

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const videoComponent = (
    <>
      <Box className="mt-10">
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          controls
          controlsList="nodownload"
          autoPlay
          onLoadedMetadata={handleVideoLoadedMetadata}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleVideoEnded}
          data-test-id="finance-process-video"
          data-video-ready="false"
          data-video-completed="false"
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <StyledTypographyGrey
        variant="body1"
        className="mt-8 italic !font-normal"
      >
        {t("VIDEO_ENDED_MESSAGE")} {!videoEnded && formatTime(remainingTime)}
      </StyledTypographyGrey>
      <div className="flex justify-end gap-10 my-20">
        <Button
          variant="outlined"
          onClick={handleFinanceProcessDialogClose}
          className="capitalize"
          data-test-id="disagree-application-submission-btn"
        >
          {t("DISAGREE")}
        </Button>
        <ColoredSubmitButton
          onClick={handleSendOtp}
          text={`${t("AGREE")} ${!videoEnded ? formatTime(remainingTime) : ""}`}
          className="px-20"
          isLoading={isGenerateOTPLoading}
          disabled={currentTime < videoDuration}
          data-test-id="agree-application-submission-btn"
        />
      </div>
    </>
  );

  return (
    <>
      {isSignatureDialogOpen && (
        <CustomDialog
          open={isSignatureDialogOpen}
          onClose={handleSignatureDialogClose}
          content={{
            title: t("SIGNATURE"),
            description: t("SIGNATURE_INFO"),
            component: signatureComponent,
          }}
          maxWidth="xs"
        />
      )}
      {verificationDialogOpen && (
        <CustomDialog
          open={verificationDialogOpen}
          onClose={handleVerificationDialogClose}
          content={{
            title: t("VERIFY_OTP"),
            component: verificationComponent,
          }}
          maxWidth="xs"
        />
      )}
      {financeProcessDialogOpen && (
        <CustomDialog
          open={financeProcessDialogOpen}
          onClose={handleFinanceProcessDialogClose}
          content={{
            title: "",
            component: videoComponent,
          }}
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default FinalApplicationSubmit;
