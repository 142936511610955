import { Box, Container, Grid, lighten } from "@mui/material";
import themesConfig from "app/configs/themesConfig";
import DashboardWelcomePage from "app/shared-components/Dashboard/DashboardWelcomePage";
import AgentDashboard from "./AgentDashboard";
import { useSelector } from "react-redux";
import { getSlug } from "app/store/user/userSlice";
import MiniAgentDashboard from "./MiniAgentDashboard";
import CustomerDashboard from "./CustomerDashboard";
import { loginUserRoles } from "src/app/common/constant";

const Dashboard = () => {
  const userSlug = useSelector(getSlug);

  return (
    <Box
      sx={{
        backgroundColor: themesConfig.default.palette.background.default,
      }}
      className="min-h-[60vh] h-full"
    >
      <Container maxWidth="lg" className="px-10 my-10 md:px-64">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DashboardWelcomePage />
          </Grid>
          {userSlug === loginUserRoles.Agent && (
            <Grid item xs={12}>
              <AgentDashboard />
            </Grid>
          )}
          {userSlug === loginUserRoles.MiniAgent && (
            <Grid item xs={12}>
              <MiniAgentDashboard />
            </Grid>
          )}
          {userSlug === loginUserRoles.Customer && (
            <Grid item xs={12}>
              <CustomerDashboard />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
