import React, { useCallback, useRef, useState } from "react";
import { Container, TextField, IconButton, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import themesConfig from "app/configs/themesConfig";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import User from "../User";
import MyApplicationDetail from "./finance-detail-page/MyApplicationDetail";
import StatusChip from "../../../shared-components/statusChip/StatusChip";
import { useSelector } from "react-redux";
import {
  getApplicationListData,
  getApplicationListLoading,
  getFilters,
  getPaginationData,
  setPagination,
  setFilters,
  getSlug,
} from "app/store/user/userSlice";
import {
  StyledButton,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { formatAmount, hasPermission } from "src/app/services/utils";
import { getApplicationList } from "app/store/user/userSlice";
import { useDispatch } from "react-redux";
import ReactTableCustom from "app/shared-components/ReactTable/ReactTableCustom";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import {
  handleQueryParamsAndFilters,
  useQueryParams,
} from "src/app/services/queryParamsUtils";
import { useDebounce } from "@fuse/hooks";
import CommonTableMenu from "app/shared-components/TableMenu/CommonTableMenu";
import history from "@history";
import CustomTooltip from "app/shared-components/Tooltip/CustomTooltip";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import {
  setActiveFinanceDetail,
  setIsDocumentSignatureDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import SignatureForm from "app/shared-components/signature-canvas/SignatureForm";
import AgentDetailTooltip from "app/shared-components/Tooltip/AgentDetailTooltip";
import MemberDetailTooltip from "app/shared-components/Tooltip/MemberDetailTooltip";
import {
  financeApplicationStatusEnum,
  loginUserRoles,
} from "src/app/common/constant";

const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    top: "-6px",
    maxWidth: `calc(100% - 60px)`,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: "0",
    maxWidth: "100%",
  },
  "& .MuiInputBase-root": {
    minWidth: "377px",
    maxHeight: "40px",
    boxShadow: `2px 4px 5px ${themesConfig.default.palette.primary.contrastText}`,
  },
  "@media (max-width: 600px)": {
    "& .MuiInputBase-root": {
      minWidth: "auto",
    },
  },
});

export default function MyApplication({userId='', isOnManagerDetailPage=false}) {
  const { t } = useTranslation("user");
  const userSlug = useSelector(getSlug);
  const reloadTable: any = useRef();
  const applicationListData = useSelector(getApplicationListData);
  const dispatch: any = useDispatch();
  const pagination = useSelector(getPaginationData);
  const loading = useSelector(getApplicationListLoading);
  const filter = useSelector(getFilters);
  const { getQueryParams, updateQueryParams } = useQueryParams();
  const queryParams = getQueryParams();
  if (!hasPermission(FeaturePermissions.FinanceApplication.List)) {
    return <ErrorMessage errorMessage="ACCESS_DENIED_VIEW_APPLICATION" />;
  }

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortField, sortOrder }) => {
      handleQueryParamsAndFilters({
        dispatch,
        setFilters,
        updateQueryParams,
        filter,
        pageSize,
        pageIndex,
        sortField,
        sortOrder,
        setPaginationAction: setPagination,
      });
      dispatch(getApplicationList({ pageIndex, userId: userId }));
    },
    [dispatch]
  );

  const getFinanceApplication = useDebounce(() => {
    if (reloadTable.current) {
      reloadTable.current.reloadData();
    }
  }, 1500);

  const onChange = (event) => {
    const keyword = event.target.value;
    dispatch(setFilters({ searchKeyword: keyword }));
    updateQueryParams({ ...queryParams, searchKeyword: keyword });
    getFinanceApplication();
  };

  const startIndex = pagination?.pageSize * pagination?.pageIndex;

  const columns = React.useMemo<any[]>(
    () => [
      {
        header: "Sr.",
        cell: (value) => {
          const index = (startIndex + value.row.index + 1)
            .toString()
            .padStart(2, "0");
          return <div>{index}</div>;
        },
      },
      {
        header: () => t("APPLICATION_ID"),
        accessorKey: "applicationNumber",
        cell: (value) => {
          return (
            <div className="flex justify-start items-center gap-5">
              <Link
                to={`/user/my-application/${encodeURIComponent(
                  value.row.original.id
                )}`}
              >
                {value.getValue()}
              </Link>
              {value.row.original?.ekycStatus?.toLowerCase() === "success" && (
                <CustomTooltip title={t("KYC_COMPLETE")}>
                  <div>
                    <FactCheckIcon color="success" />
                  </div>
                </CustomTooltip>
              )}
            </div>
          );
        },
      },
      ...(["agent", "mini-agent"].includes(userSlug)
        ? [
            {
              header: () => t("MEMBER_NAME"),
              accessorKey: "userDetail",
              cell: (value) =>
                value.getValue() ? (
                  <MemberDetailTooltip memberDetail={value.getValue()} />
                ) : (
                  "--"
                ),
            },
          ]
        : []),
      {
        header: () => t("FINANCE_PRODUCT"),
        accessorKey: "financeProductName",
      },
      {
        header: () => t("CATEGORY_NAME"),
        accessorKey: "categoryName",
      },
      {
        header: () => t("AMOUNT"),
        accessorKey: "financeAmount",
        cell: (value) => {
          return (
            <div className="text-right">{formatAmount(value.getValue())}</div>
          );
        },
      },
      {
        header: () => t("INTEREST_RATE"),
        accessorKey: "interestRate",
        cell: (value) => {
          return <div className="text-right">{value.getValue()}%</div>;
        },
      },
      {
        header: () => t("AGENT_CODE"),
        accessorKey: "agentUser",
        cell: (value) => {
          return (
            <div className="text-center">
              {value.getValue() ? (
                <AgentDetailTooltip agentDetail={value.getValue()} />
              ) : (
                "--"
              )}
            </div>
          );
        },
      },
      {
        header: () => t("STATUS"),
        accessorKey: "financeApplicationStatus",
        cell: (value) => {
          const statusValue = value.getValue();
          return statusValue ? (
            <StatusChip label={statusValue} status={statusValue} />
          ) : (
            <span>--</span>
          );
        },
      },
      {
        header: () => t("CREATED_DATE"),
        accessorKey: "created",
        cell: (value) => {
          return <div>{value.getValue()}</div>;
        },
      },
      {
        id: "action",
        header: "",
        accessor: "id",
        cell: (value) => {
          const isKycNotSuccessful =
            userSlug === loginUserRoles.Customer &&
            value.row.original?.financeApplicationStatus?.toLowerCase() ===
              "pendingkyc" &&
            value.row.original?.ekycStatus &&
            value.row.original?.ekycStatus?.toLowerCase() !== "success";

          const isDocumentSigned =
            userSlug === loginUserRoles.Customer &&
            value.row.original?.financeApplicationStatus?.toLowerCase() ===
              financeApplicationStatusEnum.DocumentSigning.toLowerCase() &&
            !value.row.original?.digitalSignatureDetails?.isCustomerSinged && hasPermission(FeaturePermissions.DigitalSignature.Create);

          let menuItems = [
            {
              label: t("VIEW_DETAILS"),
              onClick: () => {
                history.push(
                  `/user/my-application/${encodeURIComponent(
                    value.row.original.id
                  )}`
                );
              },
            },
          ];

          // If KYC is not successful, add the KYC_PROCESS menu item
          if (isKycNotSuccessful) {
            menuItems.push({
              label: t("KYC_PROCESS"),
              onClick: () => {
                history.push(
                  `/user/my-application-kyc/${encodeURIComponent(
                    value.row.original.id
                  )}`
                );
              },
            });
          }

          // If Document sign is not True, add the DOCUMENT_SIGNED menu item
          if (isDocumentSigned) {
            menuItems.push({
              label: t("DOCUMENT_SIGNED"),
              onClick: () => {
                dispatch(setActiveFinanceDetail(value.row.original));
                dispatch(setIsDocumentSignatureDialogOpen(true));
              },
            });
          }

          return (
            <div className="flex justify-end">
              <CommonTableMenu menuItems={menuItems} />
            </div>
          );
        },
      },
    ],
    [t, startIndex]
  );

  return (
    <>
      <Routes>
        <Route path="/user/*" element={<User />} />
        <Route
          path="/user/my-application/:applicationId"
          element={<MyApplicationDetail />}
        />
      </Routes>
      <Box
        sx={{
          backgroundColor: themesConfig.default.palette.background.default,
        }}
        className="h-full"
      >
        <Container
          maxWidth="lg"
          className={isOnManagerDetailPage ? `pl-20 pr-20 mb-64 mt-10` : `pl-10 md:pl-76 pr-10 md:pr-76 mb-64 mt-10`}
        >
          {!isOnManagerDetailPage && (
            <CommonBreadcrumbs currentPageName={t("MY_APPLICATION")} />
          )}
          <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full">
            <Can
              permission={FeaturePermissions.FinanceApplication.List}
              errorMessage="ACCESS_DENIED_VIEW_APPLICATION"
            >
              <>
                <Box
                  className="mb-20"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography
                    variant="h5"
                    fontWeight={"bold"}
                    className="text-2xl sm:text-3xl"
                  >
                    {isOnManagerDetailPage ? t("FINANCE_APPLICATION") :t("MY_APPLICATION")}
                  </StyledTypography>
                  {!isOnManagerDetailPage && (
                    <Can
                      permission={FeaturePermissions.FinanceApplication.Create}
                    >
                      <StyledButton
                        className="whitespace-nowrap"
                        data-test-id="apply-finance-btn"
                        onClick={() => {
                          history.push("/application-form/0");
                        }}
                      >
                        {t("APPLY_FOR_FINANCE")}
                      </StyledButton>
                    </Can>
                  )}
                </Box>
                <StyledTextField
                  label={t("SEARCH_FIELD_LABEL")}
                  variant="outlined"
                  type="search"
                  fullWidth
                  onChange={onChange}
                  value={filter.searchKeyword}
                  InputProps={{
                    endAdornment: (
                      <IconButton disabled>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
                <ReactTableCustom
                  fetchData={fetchData}
                  columns={columns}
                  data={applicationListData}
                  isLoading={loading}
                  pageCount={pagination?.totalCount}
                  paginationState={getQueryParams()}
                  ref={reloadTable}
                />
              </>
            </Can>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
