import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomTextFieldWithIcon from "app/shared-components/Form/CustomTextFieldWithIcon";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { generateAndVerifyOTPType, roleId, tenantId } from "src/app/common/constant";
import JwtService from "src/app/auth/services/jwtService";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import InfoDialog from "app/shared-components/Dialog/InfoDialog";
import ConfirmTermsAndConditions from "./ConfirmTermsAndConditions";
import OtpVerification from "app/shared-components/OtpVerification/OtpVerification";
import { removeLeadingZero } from "src/app/services/utils";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useSearchParams } from "react-router-dom";
import { phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  paddingRight: "96px",
  paddingLeft: "96px",
  marginTop: "20px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  // fontWeight: "bold",
  color: theme.palette.common.white,
  marginTop: "20px",
}));

const StyledSpan = styled("span")`
  color: ${({ theme }) => theme.palette.common.white};
`;

const defaultValues = {
  fullName: "",
  phoneNumber: "",
  email: "",
  userUniqueCode: "",
};

export default function SignUp({ switchTab }) {
  const theme = useTheme();
  const { t } = useTranslation("signInPage");
  const methods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] =
    useState(false);
  const [otp, setOtp] = useState(null);
  const [search] = useSearchParams();
  const userUniqueCode = search.get("agent-code");

  const schema = yup.object().shape({
    fullName: yup.string().required(t("FULL_NAME_VALIDATION")),
    phoneNumber: phoneNumberValidation(true),
    email: yup
      .string()
      .email(t("EMAIL_VALIDATION_EMAIL"))
      .required(t("EMAIL_VALIDATION_REQUIRED"))
      .test(
        "valid-domain",
        t("EMAIL_VALIDATION_EMAIL"),
        (value) => value && /\.[A-Za-z]{2,}$/.test(value.split("@")[1])
      ),
      userUniqueCode: yup
      .string()
      .nullable() // Allow null or empty value without triggering validation
      .matches(/^\d+$/, {
        message: t("NUMBER_VALIDATION"),
        excludeEmptyString: true, // Prevents firing on empty values
      })
      .test(
        "len",
        t("REFERRAL_CODE_LENGTH"),
        (val) => !val || (val.length === 6) // Only checks length if a value is present
      )
    
  });

  const { handleSubmit, formState, control, getValues, trigger, setValue } =
    useForm({
      defaultValues,
      resolver: yupResolver(schema),
      mode: "onChange",
    });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    if (userUniqueCode) {
      setValue("userUniqueCode", userUniqueCode);
      trigger("userUniqueCode")
    }
  }, [userUniqueCode]);

  const sendOTP = async () => {
    const isValidForm = await trigger();
    if (!isValidForm) {
      return;
    }

    const phoneNumber = getValues("phoneNumber");
    setLoading(true);

    JwtService.sendOTP({ mobile: phoneNumber, otpType: generateAndVerifyOTPType?.Register })
      .then(() => {
        setLoading(false);
        setOtpSent(true);
      })
      .catch((error) => {
        dispatch(showMessage({ message: error, variant: "error" }));
        setLoading(false);
      });
  };

  function onSubmit(model) {
    setLoading(true);
    const request_data = {
      ...model,
      tenantId: tenantId,
      otp: otp,
    };
    JwtService.createUser(request_data)
      .then(function (response) {
        setLoading(false);
        setOpenDialog(true);
      })
      .catch(function (error) {
        dispatch(showMessage({ message: error.message, variant: "error" }));
      })
      .finally(function () {
        setLoading(false);
      });
  }

  const handleChangePhoneNumber = () => {
    if (isDirty && otpSent) {
      setOtpSent(false);
    } else {
      return;
    }
  };

  const handleOpenTermsDialog = (data) => {
    if (isValid) {
      setTermsAndConditionsDialogOpen(true);
      setOtp(data.otp);
    }
  };

  const handleCloseTermsDialog = () => {
    setTermsAndConditionsDialogOpen(false);
  };

  const handleAgree = () => {
    handleSubmit(onSubmit)();
    setTermsAndConditionsDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/sign-in");
  };

  const handleSwitchTab = () => {
    switchTab();
    navigate("/sign-in");
  };
  return (
    <>
      <Box className="flex flex-col gap-20">
        <Box>
          <StyledTypography variant="h5" fontWeight={"bold"} data-test-id="check-signUp-title">
            {t("SIGN_UP_TITLE")}
          </StyledTypography>
          <StyledTypography variant="caption" fontWeight={"bold"}>
            {t("SIGN_UP_DESCRIPTION")}
          </StyledTypography>
        </Box>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-20"
          >
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <CustomTextFieldWithIcon
                  id="fullName"
                  required
                  label={t("FULL_NAME_LABEL")}
                  icon={<PersonIcon />}
                  onChange={field.onChange}
                  color={theme.palette.common.white}
                  error={errors.fullName}
                  value={field.value}
                  inputProps={{
                    'data-test-id': 'signUp-name-input-field' 
                  }}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <CustomTextFieldWithIcon
                    id="phoneNumber"
                    type="number"
                    required
                    label={t("PHONE_LABEL")}
                    icon={<PhoneIcon />}
                    color={theme.palette.common.white}
                    prefix="+60"
                    onChange={(e) => {
                      field.onChange(removeLeadingZero(e));
                      handleChangePhoneNumber();
                    }}
                    error={errors.phoneNumber}
                    value={field.value}
                    inputProps={{
                      'data-test-id': 'signUp-phone-number-input-field' 
                    }}
                  />
                  <StyledSpan className="text-[10px] ml-10 -mt-10">
                    * OTP will sent to this number for verification
                  </StyledSpan>
                </>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <CustomTextFieldWithIcon
                  id="email"
                  required
                  label={t("EMAIL_LABEL")}
                  icon={<EmailIcon />}
                  color={theme.palette.common.white}
                  onChange={field.onChange}
                  error={errors.email}
                  value={field.value}
                  isUpperCase={false}
                  inputProps={{
                    'data-test-id': 'signUp-email-input-field' 
                  }}
                />
              )}
            />

            {/* Add Agent Code When Referral */}
            <Controller
              name="userUniqueCode"
              control={control}
              render={({ field }) => (
                <CustomTextFieldWithIcon
                  id="userUniqueCode"
                  type="number"
                  label={t("REFERRAL_CODE")}
                  icon={<SupervisedUserCircleIcon />}
                  color={theme.palette.common.white}
                  onChange={field.onChange}
                  error={errors.userUniqueCode}
                  value={field.value}
                  inputProps={{ maxLength: 6, 'data-test-id': 'signUp-referral-code-input-field'  }}
                />
              )}
            />

            {!otpSent && (
              <Box className="flex justify-center items-center mt-20">
                <StyledButton
                  variant="contained"
                  // type="submit"
                  className="capitalize"
                  onClick={sendOTP}
                  data-test-id='signUp-send-otp-btn'
                >
                  {!loading ? (
                    t("SEND_OTP")
                  ) : (
                    <CircularProgress
                      color="secondary"
                      size={24}
                    ></CircularProgress>
                  )}
                </StyledButton>
              </Box>
            )}
          </form>
        </FormProvider>
        {otpSent && (
          <OtpVerification
            onOtpSubmit={handleOpenTermsDialog}
            onResendOtp={sendOTP}
            color={theme.palette.common.white}
            loading={loading}
            phoneNumber={getValues("phoneNumber")}
          />
        )}
        <Box className="flex justify-center items-center mb-80">
          <StyledTypography variant="body2">
            {t("ALREADY_ACCOUNT")}
            <span
              onClick={handleSwitchTab}
              className="underline ml-8 cursor-pointer"
            >
              {t("LOGIN_NOW")}
            </span>
          </StyledTypography>
        </Box>
      </Box>
      <ConfirmTermsAndConditions
        open={termsAndConditionsDialogOpen}
        onClose={handleCloseTermsDialog}
        onAgree={handleAgree}
      />
      <InfoDialog
        open={openDialog}
        title={t("USER_CREATE_TITLE")}
        message={t("SIGN_UP_MESSAGE")}
        buttonText={t("LOGIN_NOW")}
        onButtonClick={handleCloseDialog}
        icon={CheckCircleOutlineIcon}
        color="success"
      />
    </>
  );
}
