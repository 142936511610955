import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DigitalProcessSignature,
  generateFromJPDF,
  getActiveFinanceDetail,
  getDocumentSignJPDF,
  getProcessSignatureLoading,
  setActiveFinanceDetail,
  setDocumentSignJPDF,
  setIsDocumentSignatureDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { digitalSignatureRole } from "src/app/common/constant";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import CustomCheckbox from "../Form/CustomCheckbox";
import { formatAmount, formatDate, removeLocalStorageItem, setLocalStorageItem } from "src/app/services/utils";
import { isEmpty } from "lodash";
import PDFPreviewForTheSignature from "./PDFPreviewForTheSignature";

function DocumentSigningDialog({ open, onClose }) {
  const { t } = useTranslation("dialog");
  const financeDetail = useSelector(getActiveFinanceDetail);
  const documentSignJPDF = useSelector(getDocumentSignJPDF);
  const isProcessSignatureLoading = useSelector(getProcessSignatureLoading);
  const dispatch: any = useDispatch();

  const handleButtonClick = () => {
    const request_data = {
      FinanceApplicationId: financeDetail.id,
      SignatureRole: digitalSignatureRole.User,
    };
    dispatch(DigitalProcessSignature(request_data));
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleCheckboxChange = (event) => {
    if (event) {
      const today = new Date().toLocaleDateString();
      setLocalStorageItem("pendingKYCDialogDate", today);
    } else {
      removeLocalStorageItem("pendingKYCDialogDate");
    }
  };

  const handleDocumentSignJPDFGenerate = () => {
    if (
      !isEmpty(financeDetail?.digitalSignatureDetails) &&
      financeDetail?.digitalSignatureDetails?.formJPdfPath
    ) {
      dispatch(
        setDocumentSignJPDF(
          financeDetail?.digitalSignatureDetails?.formJPdfPath
        )
      );
    } else {
      dispatch(generateFromJPDF(financeDetail.id));
    }
  };

  const handlePDFPreviewClose = () => {
    dispatch(setActiveFinanceDetail(null));
    dispatch(setIsDocumentSignatureDialogOpen(false));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <div className="flex flex-col p-16">
          <div className="flex flex-col items-center">
            <img
              src="assets/images/digital-signature/digitalSignature.svg"
              alt="Pending KYC"
              className="max-w-256 max-h-256 min-h-144 min-w-120 ml-32 my-20"
            />
          </div>
          <Box className="flex flex-col gap-10 mx-20">
            <Typography variant="h6" className="text-center">
              {t("DIGITAL_SIGNATURE")}
            </Typography>
            <Typography variant="body1" className="text-justify mb-5">
              {t("DIGITAL_SIGNATURE_SIGNATURE")}&nbsp;
              <strong>{financeDetail?.applicationNumber}</strong>.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {financeDetail?.financeProductName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1">
                  <strong>{t("AMOUNT")}: </strong>
                  {formatAmount(financeDetail?.financeAmount)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1">
                  <strong>{t("CREATED")}: </strong>
                  {formatDate(
                    financeDetail?.created,
                    "DD/MMM/YYYY",
                    "DD/MMM/YYYY HH:mm:ss"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <div className="flex justify-start items-center">
              <CustomCheckbox
                label={t("DONT_SHOW_AGAIN")}
                onChange={handleCheckboxChange}
              />
            </div>
          </Box>
          <div className="flex justify-end items-center gap-10">
            <ColoredSubmitButton
              variant="outlined"
              onClick={onClose}
              text={t("LATER")}
            />
            <ColoredSubmitButton
              variant="contained"
              onClick={handleDocumentSignJPDFGenerate}
              text={t("SIGN_DOCUMENT")}
            />
            <PDFPreviewForTheSignature
              filePath={documentSignJPDF}
              open={!!documentSignJPDF}
              isButtonLoading={isProcessSignatureLoading}
              onClose={() => handlePDFPreviewClose()}
              bottomButtonLabel={t("SIGN_DOCUMENT")}
              onConfirm={handleButtonClick}
              applicationDetailsData={financeDetail}
            ></PDFPreviewForTheSignature>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default DocumentSigningDialog;
