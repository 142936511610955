import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  Grid,
  Divider,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import InventoryIcon from "@mui/icons-material/Inventory";
import PercentIcon from "@mui/icons-material/Percent";
import InfoIcon from "@mui/icons-material/Info";
import CustomTooltip from "../Tooltip/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveFinanceDetail,
  getActiveFinanceDetailFromApi,
} from "./store/dashboardSlice";
import StatusChip from "../statusChip/StatusChip";
import themesConfig from "app/configs/themesConfig";
import { formatAmount, formatDate } from "src/app/services/utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import history from "@history";
import SavingsIcon from '@mui/icons-material/Savings';

const FinanceApplicationCard = () => {
  const { t } = useTranslation("dashboard");
  const dispatch: any = useDispatch();
  const financeApplicationDetail = useSelector(getActiveFinanceDetail);

  useEffect(() => {
    dispatch(getActiveFinanceDetailFromApi());
  }, [dispatch]);

  if (!financeApplicationDetail) {
    return (
      <Paper
        className="px-24 py-20 shadow rounded-2xl flex flex-col justify-center items-center gap-10"
        sx={{
          backgroundColor: themesConfig.greyDark.palette.warning.contrastText,
        }}
      >
        <Typography variant="h6" color="textSecondary">
          {t("NO_ACTIVE_FINANCE_FOUND")}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          className="capitalize"
          onClick={() => {
            history.push("/application-form/0");
          }}
        >
          {t("APPLY_FOR_FINANCE")}
        </Button>
      </Paper>
    );
  }

  return (
    <Paper
      className="px-24 py-20 shadow rounded-2xl"
      sx={{
        backgroundColor: themesConfig.greyDark.palette.warning.contrastText,
      }}
    >
      <div className="flex justify-between items-center h-32">
        <Typography className="text-lg font-medium tracking-tight leading-6">
          {t("ACTIVE_FINANCE")}
        </Typography>
      </div>
      <div className="flex flex-col gap-10">
        {/* Header Section */}
        <Box className="flex justify-between text-center">
          <div className="flex gap-10 items-center">
            <Typography variant="h6" fontWeight="bold" color="secondary">
              {financeApplicationDetail?.applicationNumber}
            </Typography>
            {/* Tooltip for Detail Page Icon */}
            <CustomTooltip title={t("GO_TO_DETAIL_TOOLTIP")}>
              <IconButton
                aria-label="Go to Detail Page"
                onClick={() => {
                  history.push(
                    `/user/my-application/${financeApplicationDetail?.id}`
                  );
                }}
              >
                <OpenInNewIcon color="secondary" />
              </IconButton>
            </CustomTooltip>
          </div>
          <CustomTooltip title={t("STATUS")}>
            <Box display="flex" alignItems="center" gap={1}>
              <StatusChip
                status={financeApplicationDetail?.financeApplicationStatus}
                label={financeApplicationDetail?.financeApplicationStatus}
              />
            </Box>
          </CustomTooltip>
        </Box>

        <Divider
          sx={{ borderColor: themesConfig.default.palette.background.paper }}
        />

        {/* Body Section */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <AccountBalanceWalletIcon color="action" />
              <Typography variant="body2">{t("FINANCED_AMOUNT")}: </Typography>
              <Typography variant="body1" fontWeight="500">
                {formatAmount(financeApplicationDetail?.financeAmount)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <PercentIcon color="action" />
              <Typography variant="body2">{t("INTEREST_RATE")}: </Typography>
              <Typography variant="body1" fontWeight="500">
                {financeApplicationDetail?.interestRate}%
              </Typography>
            </Box>
          </Grid>

          {/* Conditionally render product, category, and variant names */}
          {financeApplicationDetail?.financeProductName && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <InventoryIcon color="action" />
                <Typography variant="body2">
                  {t("FINANCE_PRODUCT")}:{" "}
                </Typography>
                <Typography variant="body1" fontWeight="500">
                  {financeApplicationDetail?.financeProductName}
                </Typography>
              </Box>
            </Grid>
          )}
          {/* code commented due to incorrect data */}
          {/* <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <SavingsIcon color="action" />
              <Typography variant="body2">
                {t("OUTSTANDING_AMOUNT")}:{" "}
              </Typography>
              <Typography variant="body1" fontWeight="500">
                {formatAmount(financeApplicationDetail?.outstandingAmount)}
              </Typography>
            </Box>
          </Grid> */}

          {financeApplicationDetail?.categoryName && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon color="action" />
                <Typography variant="body2">{t("CATEGORY_NAME")}: </Typography>
                <Typography variant="body1" fontWeight="500">
                  {financeApplicationDetail?.categoryName}
                </Typography>
              </Box>
            </Grid>
          )}

          {financeApplicationDetail?.productName && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon color="action" />
                <Typography variant="body2">{t("PRODUCT_NAME")}: </Typography>
                <Typography variant="body1" fontWeight="500">
                  {financeApplicationDetail?.productName}
                </Typography>
              </Box>
            </Grid>
          )}

          {financeApplicationDetail?.productVariantName && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon color="action" />
                <Typography variant="body2">
                  {t("PRODUCT_VARIANT_NAME")}:
                </Typography>
                <Typography variant="body1" fontWeight="500">
                  {financeApplicationDetail?.productVariantName}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <Divider
          sx={{ borderColor: themesConfig.default.palette.background.paper }}
        />

        {/* Footer Section */}
        <Box className="flex justify-between text-center">
          <Typography variant="caption" color="textSecondary">
            {t("CREATED_DATE")}: {formatDate(financeApplicationDetail?.created)}
          </Typography>
          <Typography variant="caption">
            {t("LAST_MODIFIED_DATE")}:{" "}
            {formatDate(financeApplicationDetail?.lastModified)}
          </Typography>
        </Box>
      </div>
    </Paper>
  );
};

export default FinanceApplicationCard;
