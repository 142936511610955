import FuseScrollbars from "@fuse/core/FuseScrollbars";
import FuseUtils from "@fuse/utils";
import Input from "@mui/material/Input";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/material/Box";
import { lighten } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addNewChat,
  getChatContactById,
  getChatContactList,
  getChatContactsListData,
  getChatContactsListLoading,
  getChatContactsListPaginationData,
  getContactList,
  getContactsListData,
  getIsContactListLoading,
  setChatContactList,
  setChatContactsListLoading,
  setChatContactsListPagination,
  setContactList,
  setIsContactListLoading,
  setSelectedConversationUserDetail,
  startConversion,
} from "../../store/messengerAppSlice";
import ChatListItem from "./ChatListItem";
import UserAvatar from "./UserAvatar";
import { getLoginUserData, getSlug } from "app/store/user/userSlice";
import type { Chat, Contact } from "./index";
import { isEmpty, throttle } from "lodash";
import { useParams } from "react-router";
import { hasPermission } from "src/app/services/utils";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { useTranslation } from "react-i18next";
import {
  agentRoleId,
  loginUserRoles,
  miniAgentRoleId,
  roleId,
  signalRFinanceHubEnum,
} from "src/app/common/constant";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import history from "@history";
import { useDebounce } from "@fuse/hooks";
import ContactListItem from "./ContactListItem";
import moment from "moment";
import connection from "src/app/services/signalRService";
import { useSearchParams } from "react-router-dom";

const selectRadioOptions = [
  { label: "MEMBER", value: roleId },
  { label: "REGIONAL_MANAGER", value: miniAgentRoleId },
];

/**
 * The main sidebar.
 */
function MainSidebar() {
  const [searchText, setSearchText] = useState("");
  const dispatch: any = useDispatch();
  const chatContacts = useSelector(getChatContactsListData);
  const loginUserData = useSelector(getLoginUserData);
  const loginUserSlug = useSelector(getSlug);
  const memberList = useSelector(getContactsListData);
  const { t } = useTranslation("messenger");
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("id");
  const conversationRef = useRef<HTMLDivElement>(null);
  const conversionPagination = useSelector(getChatContactsListPaginationData);
  const isUserListPermission = hasPermission(FeaturePermissions.User.List);
  const isChatListPermission = hasPermission(
    FeaturePermissions.ChatMessage.List
  );
  const isPermissionToCreateConversion = hasPermission(
    FeaturePermissions.ChatMessage.Create
  );
  const isChatContactLoading = useSelector(getChatContactsListLoading);
  const isContactListLoading = useSelector(getIsContactListLoading);

  const getRoleIds = (userSlug: string) => {
	const roleMapping = {
	  [loginUserRoles.Agent]: [roleId, miniAgentRoleId],
	  [loginUserRoles.MiniAgent]: [roleId]
	};
	
	return roleMapping[userSlug] || null;
  };

  const roleIds = useMemo(() => {
	return getRoleIds(loginUserSlug);
   }, [loginUserSlug]);

  useEffect(() => {
    if (isChatListPermission) {
      dispatch(getChatContactList());
    }
  }, [dispatch]);

  const getSearchContact = useDebounce(() => {
    dispatch(
      setChatContactsListPagination({
        pageIndex: 0,
        pageSize: conversionPagination.pageSize,
      })
    );
    dispatch(setChatContactList([]));
    if (searchText) {
      dispatch(getChatContactList(searchText));

      // INFO: For MiniAgent and Agent
      if (
        isUserListPermission &&
        isPermissionToCreateConversion &&
        [loginUserRoles.Agent, loginUserRoles.MiniAgent].includes(loginUserSlug)
      ) {
		if(roleIds && roleIds.length > 0 ) {
			dispatch(
			getContactList({
				roleId: JSON.stringify(roleIds),
				searchTerm: searchText,
			})
			);
		}
      }
    } else {
      dispatch(setContactList([]));
      dispatch(getChatContactList());
      dispatch(setIsContactListLoading(false));
    }
  }, 1500);

  function handleSearchText(event) {
    setSearchText(event.target.value);
    dispatch(setChatContactsListLoading(true));
    if (
      isUserListPermission &&
      isPermissionToCreateConversion &&
      [loginUserRoles.Agent, loginUserRoles.MiniAgent].includes(loginUserSlug)
    ) {
      dispatch(setIsContactListLoading(true));
    }
    getSearchContact();
  }


  const filteredChats: Chat[] = useMemo(() => {
    if (!chatContacts) return [];

    const sortedChats = [...chatContacts]
      .filter((chat) => chat.lastChatMessage?.created || chat.created)
      .sort((a, b) => {
        const dateA = moment(a.lastChatMessage?.created || a.created).valueOf();
        const dateB = moment(b.lastChatMessage?.created || b.created).valueOf();
        return dateB - dateA;
      });

    return sortedChats;
  }, [chatContacts, memberList, searchText]);

  const filteredContacts: Contact[] = useMemo(() => {
    if (!memberList) return [];
    const chatContactIds = chatContacts.flatMap((chat) => {
      const ids = [chat.userOneId, chat.userTwoId];
      return ids.filter((id) => id !== loginUserData.id);
    });

    const filteredMemberList = memberList.filter(
      (member) => !chatContactIds.includes(member.id)
    );
    return filteredMemberList;
  }, [memberList, chatContacts]);

  useEffect(() => {
    if (!isEmpty(chatContacts) && !!conversationId) {
      const chatContact = chatContacts?.find(
        (chat) => chat.id === conversationId
      );
      if (!isEmpty(chatContact?.userDetail)) {
        dispatch(setSelectedConversationUserDetail(chatContact.userDetail));
      } else if (!!conversationId) {
        dispatch(getChatContactById({ conversationId: conversationId })).then(
          (response) => {
            if (!isEmpty(response?.userDetail)) {
              dispatch(setSelectedConversationUserDetail(response?.userDetail));
            } else {
              history.replace("/user/messenger");
            }
          }
        );
      }
    }
  }, [dispatch, chatContacts, conversationId]);

  // For The Load More Conversion Contact When Scroll ON Bottom
  useEffect(() => {
    const handleScroll = throttle(() => {
      if (!conversationRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = conversationRef.current;

      if (scrollHeight - scrollTop <= clientHeight + 100) {
        if (
          conversionPagination.totalCount > chatContacts.length &&
          !isChatContactLoading
        ) {
          dispatch(
            setChatContactsListPagination({
              pageIndex: conversionPagination.pageIndex + 1,
              pageSize: conversionPagination.pageSize,
            })
          );

          dispatch(getChatContactList(searchText));
        }
      }
    }, 1500); // Throttled scroll handling

    const pageNode = conversationRef.current;
    if (pageNode) {
      pageNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (pageNode) {
        pageNode.removeEventListener("scroll", handleScroll);
      }
    };
  }, [dispatch, chatContacts, conversionPagination]);

  // INFO: For SignalR Connection To OneToOne Chat
  useEffect(() => {
    connection.on(signalRFinanceHubEnum.OneToOneChat, (newMessage) => {
      if (conversationId === newMessage?.conversationId) {
        dispatch(addNewChat(newMessage));
        if (!isEmpty(chatContacts)) {
          const updatedChatContacts = chatContacts?.map((chat) =>
            chat.id === newMessage.conversationId
              ? {
                  ...chat,
                  lastChatMessage: newMessage,
                  lastModified: newMessage.lastModified,
                }
              : chat
          );
          dispatch(setChatContactList(updatedChatContacts));
        }
      } else if (!isEmpty(chatContacts)) {
        const chatContact = chatContacts?.find(
          (chat) => chat.id === newMessage?.conversationId
        );

        if (!isEmpty(chatContact)) {
          const updatedChatContacts = chatContacts?.map((chat) =>
            chat.id === newMessage.conversationId
              ? {
                  ...chat,
                  lastChatMessage: newMessage,
                  lastModified: newMessage.lastModified,
                }
              : chat
          );

          dispatch(setChatContactList(updatedChatContacts));
        } else if (!!newMessage?.conversationId) {
          dispatch(
            getChatContactById({ conversationId: newMessage?.conversationId })
          );
        }
      } else if (!!newMessage?.conversationId) {
        dispatch(
          getChatContactById({ conversationId: newMessage?.conversationId })
        );
      }
    });

    return () => {
      connection.off(signalRFinanceHubEnum.OneToOneChat);
    };
  }, [dispatch, conversationId, chatContacts]);

  return (
    <div className="flex flex-col flex-auto h-full overflow-hidden">
      <Box
        className="py-16 px-16 border-b-1 sticky top-0 z-10"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <div className="flex justify-between items-center mb-5">
          {loginUserData && (
            <div
              className="flex items-center cursor-pointer"
              role="button"
              tabIndex={0}
            >
              <UserAvatar className="relative" user={loginUserData} />
              <Typography className="mx-16 font-medium">
                {loginUserData?.fullName}
              </Typography>
            </div>
          )}
        </div>

        {/* {isUserListPermission && isPermissionToCreateConversion && [loginUserRoles.Agent].includes(loginUserSlug) &&
					<>
						<FormControl className='ml-5'>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								defaultValue={selectRadioOptions[0].value}
								onChange={handleSelectionChange}
							>
								{selectRadioOptions.map((option) => (
									<FormControlLabel
										key={option.value}
										value={option.value}
										control={<Radio size="small" />}
										label={t(option.label)}
									/>
								))}
							</RadioGroup>
						</FormControl>
					</>
				} */}

        {useMemo(
          () => (
            <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-8 shadow-none">
              <FuseSvgIcon color="action" size={20}>
                heroicons-solid:search
              </FuseSvgIcon>

              <Input
                placeholder={t("SEARCH_OR_START_NEW_CHAT")}
                className="flex flex-1 px-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={handleSearchText}
              />
              {searchText.trim() &&
                (isContactListLoading || isChatContactLoading) && (
                  <CircularProgress size={18} color="secondary" />
                )}
            </Paper>
          ),
          [searchText, t, isContactListLoading, isChatContactLoading]
        )}
      </Box>

      <FuseScrollbars className="flex-1">
        <List className="w-full">
          {useMemo(() => {
            if (!memberList || !chatContacts) {
              return null;
            }

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.02,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 10 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <motion.div
                className="flex flex-col shrink-0 min-h-[10vh] max-h-[100vh] lg:max-h-[60vh] overflow-y-auto"
                variants={container}
                initial="hidden"
                animate="show"
                ref={conversationRef}
              >
                {filteredChats.length > 0 && (
                  <motion.div variants={item}>
                    <Typography
                      className="font-medium text-20 px-16 py-10"
                      color="secondary.main"
                    >
                      {t("CONVERSATIONS")}
                    </Typography>
                  </motion.div>
                )}

                {filteredChats &&
                  filteredChats.map((chat, index) => (
                    <motion.div variants={item} key={chat.id}>
                      <div
                        className={clsx(
                          filteredChats.length !== index + 1 && "border-b-1"
                        )}
                      >
                        <ChatListItem item={chat} />
                      </div>
                    </motion.div>
                  ))}

                {isPermissionToCreateConversion && (
                  <>
                    {filteredContacts.length > 0 && (
                      <motion.div variants={item}>
                        <Typography
                          className="font-medium text-20 px-16 py-24"
                          color="secondary.main"
                        >
                          {t("CONTACTS")}
                        </Typography>
                      </motion.div>
                    )}

                    {!isEmpty(filteredContacts) &&
                      filteredContacts.map((contact, index) => (
                        <motion.div variants={item} key={contact.id}>
                          <div
                            className={clsx(
                              filteredContacts.length !== index + 1 &&
                                "border-b-1"
                            )}
                          >
                            <ContactListItem item={contact} />
                          </div>
                        </motion.div>
                      ))}
                  </>
                )}
              </motion.div>
            );
          }, [memberList, filteredChats, searchText, t])}

          {isEmpty(filteredChats) && isChatContactLoading && (
            <Box className="flex justify-center items-center absolute top-0 w-full z-10 mt-10">
              <CircularProgress size={24} color="secondary" />
            </Box>
          )}
        </List>
      </FuseScrollbars>

      {/* IF Not Found Conversation And Contact */}
      {isEmpty(chatContacts) &&
        isEmpty(filteredContacts) &&
        !isChatContactLoading &&
        !isContactListLoading && (
          <div className="flex flex-col justify-center absolute items-center w-full h-full text-center py-5 top-0">
            <Typography variant="h6" className="text-gray-500">
              {t("NOT_CONVERSATION_AND_CHAT_FOUND")}
            </Typography>
          </div>
        )}
    </div>
  );
}

export default MainSidebar;
